import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { Footer } from "../components/includes/Footer";
import { Header } from "../components/includes/Header";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";
// import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
//import "react-simple-tree-menu/dist/main.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UserService from "../services/user.service";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ApexPowerChart from "../components/ApexPowerChartNew";
import IelDc from "../components/IelDc";
import ApexTempetureChart from "../components/ApexTempetureChart";
import PowerChart from "../components/PowerChart";
import PowerCharts from "../components/PowerCharts";
import TempetureChart from "../components/TempetureChart";
import EnergyChart from "../components/EnergyChart";
import socketClient from "socket.io-client";
import VirtualDevice from "../components/VirtualDevice";
import VirtualDeviceMorroco from "../components/Morocco/Virtual/VirtualDeviceMorroco.js";
import PucDashboard from "../components/Puc/PucDashboard";
import Morocco from "../components/Morocco";
import New_Morocco from "../components/Morocco_new";
import {
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import {
  ExpandMore,
  ChevronRight,
  HelpOutlineOutlined,
  DeviceThermostat,
  Power,
  ElectricBolt,
  KeyboardArrowDown,
  Devices,
} from "@mui/icons-material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Control from "../components/Control";
import DeviceStats from "../components/DeviceStats";
import DashBox from "../components/DashBox";
import DashBox2 from "../components/DashBox2";
import HighchartsGraph from "../components/Highcharts";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { color } from "highcharts";
import { fontSize } from "@mui/system";
import SharedList from "./SharedList";
import ManageAccessProfiles from "./ManageAccessProfiles.js";
const tzone = "Asia/Amman";

// const SocketServer = "http://localhost:5010/";
// const SocketServer = "http://192.81.215.165:5001/";
// const SocketServer = "https://192.81.215.165/";
// const SocketServer = "https://agile-portal.info/";

// const SocketServer = "http://161.35.70.245:5010/";
const SocketServer = process.env.REACT_APP_SOCKET_URL_DEV;
// const SocketServer = "https://161.35.70.245/";
// const SocketServer = "https://161.35.70.245/";
const connectionOptions = {
  //"force new connection": true,
  //"reconnectionAttempts": "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 10000, //before connect_error and connect_timeout are emitted.
  transports: ["websocket"],
};

const Dashboard = () => {
  //set states start here
  const [isstartDateChange, setIsstartDateChange] = useState("");
  const [isstartDate, setIsstartDate] = useState("");
  const [isEnergyTxt, setisEnergyTxt] = useState("Energy");
  const [isUpdateData, setisUpdateData] = useState("");
  const [addDeviceBtnText, setAddDeviceBtnText] = useState("Verify");
  const [showWelcomeDiv, setshowWelcomeDiv] = useState(true);
  const [stepOne, setstepOne] = useState(true);
  const [stepTwo, setstepTwo] = useState(false);
  const [stepTwoisLoading, setstepTwoisLoading] = useState(false);
  const [forgotisLoading, setforgotisLoading] = useState(false);
  const [deleteAreaisLoading, setdeleteAreaisLoading] = useState(false);
  const [isaddDeviceToUser, setisaddDeviceToUser] = useState(false);
  const [renameAreaisLoading, setrenameAreaisLoading] = useState(false);
  const [isAddArea, setIsAddArea] = useState(false);
  const [isAddDevice, setIsAddDevice] = useState(false);
  const [isVirtualDeviceAdd, setIsVirtualDeviceAdd] = useState(false);
  const [isforgotdDevice, setIsForgotDevice] = useState(false);
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  const [isDeleteArea, setIsDeleteArea] = useState(false);
  const [isRenameArea, setIsRenameArea] = useState(false);
  const [isRenameDevice, setIsRenameDevice] = useState(false);
  const [isMoveDevice, SetIsMoveDevice] = useState(false);
  const [isAddDeviceTouser, SetIsAddDeviceTouser] = useState(false);
  const [content, setContent] = useState([]);
  const [contentDevice, setContentDevice] = useState([]);
  const [contentArea, setContentArea] = useState([]);
  const [treeViewData, setTreeViewData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isAddDeviceLoading, setisAddDeviceLoading] = useState(false);
  const [isGetDeviceLoading, setisgetDeviceLoading] = useState(false);
  const [showGraph, setshowGraph] = useState(false);
  const [areaName, setAreaName] = useState("");
  const [isSharedDevice, setIsSharedDevice] = useState(false);
  const [renameDeleteId, setRenameDeleteId] = useState("");
  const [devicename, setDeviceName] = useState("");
  const [sharedProfile, setSharedProfile] = useState("");

  const [isPower, setisPower] = useState(false);
  const [isTemperature, setisTemperature] = useState(false);
  const [isControl, setisControl] = useState(false);
  const [isPowerTotal, setisPowerTotal] = useState(false);
  const [isPowerPhase1, setisPowerPhase1] = useState(false);
  const [isPowerPhase2, setisPowerPhase2] = useState(false);
  const [isPowerPhase3, setisPowerPhase3] = useState(false);

  const [isEnergyTotal, setisEnergyTotal] = useState(false);
  const [isEnergyPhase1, setisEnergyPhase1] = useState(false);
  const [isEnergyPhase2, setisEnergyPhase2] = useState(false);
  const [isEnergyPhase3, setisEnergyPhase3] = useState(false);

  const [isEnergy, setisEnergy] = useState(false);
  const [isEnergyDaily, setisEnergyDaily] = useState(false);
  const [isEnergyMonthly, setisEnergyMonthly] = useState(false);

  const [isStaticTxtValue1, setisStaticTxtValue1] = useState("Frequency");
  const [isStaticTxtValue2, setisStaticTxtValue2] = useState("Power Factor");
  const [isStaticTxtValue3, setisStaticTxtValue3] = useState("T-Power");
  const [isStaticTxtValue4, setisStaticTxtValue4] =
    useState("T-Energy (Monthly)");

  const [isStaticValue1, setisStaticValue1] = useState("---");
  const [isStaticValue2, setisStaticValue2] = useState("---");
  const [isStaticValue3, setisStaticValue3] = useState("---");
  const [isStaticValue4, setisStaticValue4] = useState("---");
  const [isStaticTemperature, setisStaticTemperature] = useState("0");
  const [isFilterGraphData, setIsFilterGraphData] = useState(false);
  const [isFilterTempData, setIsFilterTempData] = useState(false);
  const [isFilterTemData, setIsFilterTemData] = useState(false);

  const [isGraphLabelTxt, setisGraphLabelTxt] = useState("Total Power");
  const [isDeviceStatus, setisDeviceStatus] = useState("yellow");
  const [isActiveRangeSwitch, setisActiveRangeSwitch] = useState(null);
  const [isGraphStatsLoading, setisGraphStatsLoading] = useState(true);
  const [isUpdateUseEffectSocket, setisUpdateUseEffectSocket] = useState(0);

  const [rootTreeViewData, setRootTreeViewData] = useState([]);
  const [powerDataFromDB, setpowerDataFromDB] = useState([]);
  const [tempetureDataFromFilter, settempetureDataFromFilter] = useState([]);
  const [graphDataFromFilter, setgraphDataFromFilter] = useState([]);
  const [istempetureDataFromSocket, setIstempetureDataFromSocket] =
    useState(false);
  const [isGraphDataFromSocket, setIsGraphDataFromSocket] = useState(false);
  const [tempetureDataFromSocket, settempetureDataFromSocket] = useState([]);
  const [energyDataFromDB, setenergyDataFromDB] = useState([]);
  const [monthlyenergyDataFromDB, setmonthlyenergyDataFromDB] = useState([]);
  const [isDeviceID, setisDeviceID] = useState("");
  const [DeviceObject, setDeviceObject] = useState("");
  const [isSocketConnected, setisSocketConnected] = useState(false);
  const [clickedDevice, SetclickedDevice] = useState("");

  // const [isAddVirtualDevice, setIsAddVirtualDevice] = useState(false);
  const [isVirtualDevice, setIsVirtualDevice] = useState(false);
  const [deviceID, setDeviceID] = useState("");
  const [statementName, setstatementName] = useState("");
  const [acessProfiles, setAccessProfiles] = useState([]);
  const [virtualDeviceType, setVirtualDeviceType] = useState("");
  const [displayVirtualDeviceType, setDisplayVirtualDeviceType] = useState("");

  const { user } = useSelector((state) => state.auth);
  const userID = user.data.profile.id;
  const keyRef = useRef(Date.now());

  // console.log(user);
  let today = moment().tz(tzone).format("YYYY/MM/DD");
  let pastDate = moment()
    .tz(tzone)
    .subtract(6, "month")
    .startOf("month")
    .format("YYYY/MM/DD");
  const NodeWithContextMenu = (props) => {
    const [contextMenu, setContextMenu] = React.useState({
      mouseX: null,
      mouseY: null,
    });
    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu(
        contextMenu.mouseX === null
          ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
          : {
            mouseX: null,
            mouseY: null,
          }
      );
    };

    const handleClose = (action, props) => {
      const { id, label, is_type, shared_by, is_shared_device, profile } =
        props;
      setAreaName(label);
      setRenameDeleteId(id);
      if (action == "Rename" && is_type == "area") {
        console.log("rename area");
        setIsRenameArea(true);
        SetIsAddDeviceTouser(false);
        SetIsMoveDevice(false);
        setIsRenameDevice(false);
        setIsDeleteArea(false);
        setIsForgotDevice(false);
        setIsAddDevice(false);
        setIsAddArea(false);
        setshowGraph(false);
        setIsVirtualDevice(false);
        setIsVirtualDevice(false);
        setshowWelcomeDiv(false);
        setSharedProfile(profile);
      }
      if (
        action == "Rename" &&
        (is_type == "device" || is_type == "virtual_device")
      ) {
        console.log("rename device");
        setIsRenameDevice(true);
        SetIsAddDeviceTouser(false);
        SetIsMoveDevice(false);
        setIsRenameArea(false);
        setIsDeleteArea(false);
        setIsForgotDevice(false);
        setIsAddDevice(false);
        setIsAddArea(false);
        setshowGraph(false);
        setIsVirtualDevice(false);
        setIsVirtualDevice(false);
        setshowWelcomeDiv(false);
      }
      if (action == "Delete" && is_type == "area") {
        console.log("Delete area");
        setIsDeleteArea(true);
        SetIsAddDeviceTouser(false);
        SetIsMoveDevice(false);
        setIsRenameArea(false);
        setIsRenameDevice(false);
        setIsForgotDevice(false);
        setIsAddDevice(false);
        setIsAddArea(false);
        setshowGraph(false);
        setIsVirtualDevice(false);
        setIsVirtualDevice(false);
        setshowWelcomeDiv(false);
      }
      if (
        action == "Delete" &&
        (is_type == "device" || is_type == "virtual_device")
      ) {
        console.log("Delete device");
        setIsForgotDevice(true);
        SetIsAddDeviceTouser(false);
        SetIsMoveDevice(false);
        setIsRenameArea(false);
        setIsRenameDevice(false);
        setIsDeleteArea(false);
        setIsAddDevice(false);
        setIsAddArea(false);
        setshowGraph(false);
        setIsVirtualDevice(false);
        setshowWelcomeDiv(false);
      }
      if (action == "Move") {
        console.log("move");
        SetIsMoveDevice(true);
        SetIsAddDeviceTouser(false);
        setIsForgotDevice(false);
        setIsRenameArea(false);
        setIsRenameDevice(false);
        setIsDeleteArea(false);
        setIsAddDevice(false);
        setIsAddArea(false);
        setshowGraph(false);
        setIsVirtualDevice(false);
        setshowWelcomeDiv(false);
      }
      if (action == "addUsers") {
        console.log("addUsers");
        setValue9("user_id", userID);
        setValue9("device_id", props.device_id);
        setValue9("device_name", props.label);
        SetIsAddDeviceTouser(true);
        SetIsMoveDevice(false);
        setIsForgotDevice(false);
        setIsRenameArea(false);
        setIsRenameDevice(false);
        setIsDeleteArea(false);
        setIsAddDevice(false);
        setIsAddArea(false);
        setshowGraph(false);
        setIsVirtualDevice(false);
        setshowWelcomeDiv(false);
        SetclickedDevice(props.device_id);
      }
      setContextMenu({
        mouseX: null,
        mouseY: null,
      });
    };

    return (
      <div onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
        <Typography
          onClick={(event) => {
            let {
              id,
              is_type,
              device_id: deviceID,
              label,
              shared_by,
              is_shared_device,
              profile,
            } = props;
            let device_id = deviceID.trim();
            console.log("device ++++++++++++++++++++++++", props);
            if (is_type == "device") {
              //setIsFilterGraphData(true)
              setIstempetureDataFromSocket(false);
              setIsGraphDataFromSocket(false);
              setIsSharedDevice(is_shared_device);
              setisDeviceID(device_id);
              setisPower(true);
              setisPowerTotal(true);
              setisEnergyPhase1(false);
              setisEnergyPhase2(false);
              setisEnergyPhase3(false);
              setisPowerPhase1(false);
              setisPowerPhase2(false);
              setisPowerPhase3(false);
              setisEnergy(false);
              setisTemperature(false);
              setisControl(false);
              setisEnergyDaily(false);
              setisEnergyMonthly(false);
              setshowGraph(true);
              setIsVirtualDevice(false);
              setIsRenameDevice(false);
              setIsRenameArea(false);
              setIsAddArea(false);
              setIsAddDevice(false);
              setshowWelcomeDiv(false);
              setIsForgotDevice(false);
              setIsDeleteArea(false);
              SetIsMoveDevice(false);
              SetIsAddDeviceTouser(false);
              setDeviceName(label);
              setSharedProfile(profile);
              setisActiveRangeSwitch(null);

              setisStaticTxtValue1("Frequency");
              setisStaticTxtValue2("Power Factor");
              setisStaticTxtValue3("T-Power");
              setisStaticTxtValue4("T-Energy (Monthly)");
              setisGraphLabelTxt("Total Power");

              //let areaName = parent.split("/").pop()

              //console.log("call device data api", { device_id: device_id, objectName: "T_power", dataType: null })
              let userIds = { user_id: userID, device_id: device_id };
              console.log("user connected emit to socket data :", userIds);
              io.current.emit("user_connected", userIds);
              io.current.emit("checkDeviceStatus", {
                device_id: device_id.trim(),
              });
              io.current.emit("liveStatsData", {
                user_id: userID,
                device_id: device_id,
                objectName: "T_power",
                dataType: null,
              });
              io.current.emit("liveGraphData", {
                user_id: userID,
                device_id: device_id,
                objectName: "T_power_A",
                dataType: null,
              });

              UserService.GetLinkedDeviceStatus(device_id)
                .then((res) => {
                  if (res.data.message != "data_not_found") {
                    console.log(
                      "!!!!!!!!!!!! device status: ",
                      res.data.data.deviceData[0]
                    );
                    const { device_status, device_status_timestamp_diff } =
                      res.data.data.deviceData[0];
                    if (
                      device_status == 1 &&
                      device_status_timestamp_diff <= 20
                    ) {
                      setisDeviceStatus("green");
                    } else if (
                      device_status == 0 &&
                      device_status_timestamp_diff >= 21 &&
                      device_status_timestamp_diff <= 39
                    ) {
                      setisDeviceStatus("yellow");
                    } else if (
                      device_status == 0 &&
                      device_status_timestamp_diff >= 40
                    ) {
                      setisDeviceStatus("red");
                      console.log("here");
                    } else if (
                      device_status == 0 &&
                      device_status_timestamp_diff == 0
                    ) {
                      setisDeviceStatus("yellow");
                    } else {
                      setisDeviceStatus("yellow");
                    }
                  } else {
                    setisDeviceStatus("red");
                    console.log("here");
                  }
                })
                .catch((err) => console.log(err));

              UserService.GetLinkedDeviceData(device_id, "T_power_A")
                .then((res) => {
                  // console.log("!!!!!!!!!!!!!!!!!!!!**********888on change api call:", res.data.data.deviceData)
                  setIsFilterGraphData(true);

                  setgraphDataFromFilter(res.data.data.deviceData);
                  setIsstartDate(res.data.data.deviceData[0].date);
                  setIsstartDateChange(
                    new Date().getTime().toString() +
                    Math.floor(Math.random() * 1000000)
                  );
                  setTimeout(() => {
                    setIsFilterGraphData(false);
                  }, 1000);
                })
                .catch((err) => {
                  setIsFilterGraphData(false);
                  console.log(err);
                });

              //get latest stats for total voltage, current, power and energy
              setisGraphStatsLoading(true);
              UserService.GetLatestDeviceStatsData(device_id)
                .then((res) => {
                  // console.log(
                  //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
                  //   res.data.data.deviceData
                  // );
                  setTimeout(() => {
                    setisGraphStatsLoading(false);
                  }, 1000);
                  if (res.data.data.error) {
                    setisStaticValue1("0.00");
                    setisStaticValue2("0.00");
                    setisStaticValue3("0.00");
                    setisStaticValue4("0.00");
                    setisStaticTemperature("0.00");
                  } else {
                    // console.log(
                    //   "!!!!!!!!!!!!process data",
                    //   res.data.data.deviceData[0]
                    // );

                    const {
                      T_voltage,
                      T_current,
                      T_power,
                      T_energy,
                      temperature,
                    } = res.data.data.deviceData[0];

                    setisStaticValue1(T_voltage);
                    setisStaticValue2(T_current);
                    setisStaticValue3(T_power);
                    setisStaticValue4(T_energy);
                    setisStaticTemperature(temperature);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setisGraphStatsLoading(false);
                });
            }
            if (is_type === "virtual_device") {
              console.log(is_type, "virtual_device");
              setDisplayVirtualDeviceType(is_type);
              setIsVirtualDevice(true);
              setshowGraph(false);
              setIsVirtualDevice(false);
              setisDeviceID(device_id);
              setDeviceObject(props);
              setIstempetureDataFromSocket(false);
              setIsGraphDataFromSocket(false);
              setIsSharedDevice(is_shared_device);
              setisDeviceID(device_id);
              setisPower(false);
              setisPowerTotal(false);
              setisEnergyPhase1(false);
              setisEnergyPhase2(false);
              setisEnergyPhase3(false);
              setisPowerPhase1(false);
              setisPowerPhase2(false);
              setisPowerPhase3(false);
              setisEnergy(false);
              setisTemperature(false);
              setisControl(false);
              setisEnergyDaily(false);
              setisEnergyMonthly(false);
              setshowGraph(false);
              setIsVirtualDevice(false);
              setIsVirtualDevice(true);
              setIsRenameDevice(false);
              setIsRenameArea(false);
              setIsAddArea(false);
              setIsAddDevice(false);
              setshowWelcomeDiv(false);
              setIsForgotDevice(false);
              setIsDeleteArea(false);
              SetIsMoveDevice(false);
              SetIsAddDeviceTouser(false);
              setDeviceName(label);
              setisActiveRangeSwitch(null);
            }
            if (is_type === "Virtual_moroco") {
              console.log(is_type, "Virtual_moroco");
              setDisplayVirtualDeviceType(is_type);
              setIsVirtualDevice(true);
              setshowGraph(false);
              setIsVirtualDevice(false);
              setisDeviceID(device_id);
              setDeviceObject(props);
              setIstempetureDataFromSocket(false);
              setIsGraphDataFromSocket(false);
              setIsSharedDevice(is_shared_device);
              setisDeviceID(device_id);
              setisPower(false);
              setisPowerTotal(false);
              setisEnergyPhase1(false);
              setisEnergyPhase2(false);
              setisEnergyPhase3(false);
              setisPowerPhase1(false);
              setisPowerPhase2(false);
              setisPowerPhase3(false);
              setisEnergy(false);
              setisTemperature(false);
              setisControl(false);
              setisEnergyDaily(false);
              setisEnergyMonthly(false);
              setshowGraph(false);
              setIsVirtualDevice(false);
              setIsVirtualDevice(true);
              setIsRenameDevice(false);
              setIsRenameArea(false);
              setIsAddArea(false);
              setIsAddDevice(false);
              setshowWelcomeDiv(false);
              setIsForgotDevice(false);
              setIsDeleteArea(false);
              SetIsMoveDevice(false);
              SetIsAddDeviceTouser(false);
              setDeviceName(label);
              setisActiveRangeSwitch(null);
            }
            //   if (is_type == "virtual_device") {
            //   console.log("virtual_device");
            //   setIsVirtualDevice(true);
            //   setshowGraph(false);
            //   setIsVirtualDevice(false);
            //   setisDeviceID(device_id);
            //   setDeviceObject(props);
            //   setIstempetureDataFromSocket(false);
            //   setIsGraphDataFromSocket(false);
            //   setIsSharedDevice(is_shared_device);
            //   setisDeviceID(device_id);
            //   setisPower(false);
            //   setisPowerTotal(false);
            //   setisEnergyPhase1(false);
            //   setisEnergyPhase2(false);
            //   setisEnergyPhase3(false);
            //   setisPowerPhase1(false);
            //   setisPowerPhase2(false);
            //   setisPowerPhase3(false);
            //   setisEnergy(false);
            //   setisTemperature(false);
            //   setisControl(false);
            //   setisEnergyDaily(false);
            //   setisEnergyMonthly(false);
            //   setshowGraph(false);
            //   setIsVirtualDevice(false);
            //   setIsVirtualDevice(true);
            //   setIsRenameDevice(false);
            //   setIsRenameArea(false);
            //   setIsAddArea(false);
            //   setIsAddDevice(false);
            //   setshowWelcomeDiv(false);
            //   setIsForgotDevice(false);
            //   setIsDeleteArea(false);
            //   SetIsMoveDevice(false);
            //   SetIsAddDeviceTouser(false);
            //   setDeviceName(label);
            //   setisActiveRangeSwitch(null);
            // }
            //setActiveItemId(id);
            // if you want after click do expand/collapse comment this two line
            // event.stopPropagation();
            // event.preventDefault();
          }}
        >
          {props.label}
        </Typography>
        {props.is_type != "user" ? (
          props.is_shared_device == "true" ? (
            <Menu
              open={contextMenu.mouseX !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu.mouseX !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem>{props.device_id}</MenuItem>
              <MenuItem onClick={() => handleClose("Move", props)}>
                Move {props.label}
              </MenuItem>
              <MenuItem onClick={() => handleClose("Rename", props)}>
                Rename {props.label}
              </MenuItem>
              <MenuItem onClick={() => handleClose("Delete", props)}>
                Delete {props.label}
              </MenuItem>
            </Menu>
          ) : (
            <Menu
              open={contextMenu.mouseX !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu.mouseX !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              {/* <MenuItem>
                 {props.device_id}
              </MenuItem> */}
              {props.is_type == "device" ? (
                <MenuItem>{props.device_id}</MenuItem>
              ) : null}

              <MenuItem onClick={() => handleClose("Rename", props)}>
                Rename {props.label}
              </MenuItem>
              <MenuItem onClick={() => handleClose("Delete", props)}>
                Delete {props.label}
              </MenuItem>
              {/* {props.is_type !== "virtual_device" ? (
                <>
                  <MenuItem onClick={() => handleClose("Rename", props)}>
                    Rename {props.label}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("Delete", props)}>
                    Delete {props.label}
                  </MenuItem>{" "}
                </>
              ) : null} */}

              {props.is_type == "device" ? (
                <MenuItem onClick={() => handleClose("Move", props)}>
                  Move {props.label}
                </MenuItem>
              ) : null}
              {props.is_type == "device" ? (
                <MenuItem onClick={() => handleClose("addUsers", props)}>
                  Share {props.label}
                </MenuItem>
              ) : null}
            </Menu>
          )
        ) : null}
      </div>
    );
  };

  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };
  const handleCallback = (start, end, label) => {
    console.log("callback -----------", start, end, label);
  };
  const handleApply = (event, picker) => {
    setisActiveRangeSwitch(null);
    //console.log("event",event)
    console.log("start Date", picker.startDate);
    console.log("start Date", moment(picker.startDate._d).tz(tzone).format());
    console.log("End Date", picker.endDate._d.toISOString().split("T")[0]);
    const format = "daterange";

    let startDateObj = picker.startDate._d;
    let startDateObjDate = new Date(startDateObj);

    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();
    let startDate = startDateyear + "-" + startDatemonth + "-" + startDateday;
    console.log("final start date----", startDate);

    let endDateObj = picker.endDate._d;
    let endDateObjDate = new Date(endDateObj);

    let endDateday = endDateObjDate.getDate();
    let endDatemonth = endDateObjDate.getMonth() + 1;
    let endDateyear = endDateObjDate.getFullYear();
    let endDate = endDateyear + "-" + endDatemonth + "-" + endDateday;
    console.log("final end date----", endDate);

    //------------- For Temperature --------------------
    if (isTemperature) {
      console.log("isTemperature");
      UserService.GetLinkedDeviceTemperatureData(
        isDeviceID,
        "temperature",
        format,
        startDate,
        endDate
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          setIsFilterTemData(true);
          settempetureDataFromFilter(res.data.data.deviceData);
          setTimeout(() => {
            setIsFilterTemData(false);
          }, 1000);
        })
        .catch((err) => {
          setIsFilterTemData(false);
          console.log(err);
        });
    }
    //------------- For Power Data and it's Phases ----------
    if (isPower && isPowerTotal) {
      console.log("power total");
      UserService.GetLinkedDeviceData(
        isDeviceID,
        "T_power_A",
        format,
        startDate,
        endDate
      )
        .then((res) => {
          setIsGraphDataFromSocket(false);
          console.log("filter:", res.data.data.deviceData);
          setIsFilterGraphData(true);
          setgraphDataFromFilter(res.data.data.deviceData);
          setTimeout(() => {
            setIsFilterGraphData(false);
          }, 1000);
        })
        .catch((err) => {
          setIsFilterGraphData(false);
          console.log(err);
        });
    }
    if (isPower && isPowerPhase1) {
      console.log("power phase 1");
      UserService.GetLinkedDeviceData(
        isDeviceID,
        "L1_Power_A",
        format,
        startDate,
        endDate
      )
        .then((res) => {
          setIsFilterGraphData(true);
          setgraphDataFromFilter(res.data.data.deviceData);
          setTimeout(() => {
            setIsFilterGraphData(false);
          }, 1000);
        })
        .catch((err) => {
          setIsFilterGraphData(false);
          console.log(err);
        });
    }
    if (isPower && isPowerPhase2) {
      console.log("power phase 2");
      UserService.GetLinkedDeviceData(
        isDeviceID,
        "L2_Power_A",
        format,
        startDate,
        endDate
      )
        .then((res) => {
          setIsFilterGraphData(true);
          setgraphDataFromFilter(res.data.data.deviceData);
          setTimeout(() => {
            setIsFilterGraphData(false);
          }, 1000);
        })
        .catch((err) => {
          setIsFilterGraphData(false);
          console.log(err);
        });
    }
    if (isPower && isPowerPhase3) {
      console.log("power phase 3");
      UserService.GetLinkedDeviceData(
        isDeviceID,
        "L3_Power_A",
        format,
        startDate,
        endDate
      )
        .then((res) => {
          setIsFilterGraphData(true);
          setgraphDataFromFilter(res.data.data.deviceData);
          setTimeout(() => {
            setIsFilterGraphData(false);
          }, 1000);
        })
        .catch((err) => {
          setIsFilterGraphData(false);
          console.log(err);
        });
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (type) => {
    console.log("isType", type);
    if (type === "daily") {
      setisPower(false);
      setisPowerTotal(false);
      setisEnergyMonthly(false);
      setisEnergyDaily(true);
      setisEnergy(true);
      setisTemperature(false);
      setisEnergyTotal(true);
      setisEnergyPhase1(false);
      setisEnergyPhase2(false);
      setisEnergyPhase3(false);
      setisStaticTxtValue1("Frequency");
      setisStaticTxtValue2("Power Factor");
      setisStaticTxtValue3("T-Power");
      setisStaticTxtValue4("T-Energy (Monthly)");
      setisGraphLabelTxt("T-Energy-Daily");
      // *******
      UserService.GetLinkedDeviceData(isDeviceID, "T_Energy_Hr_A", "daily") //need to be refrshed
        .then((res) => {
          setenergyDataFromDB(res.data.data.deviceData);
        })
        .catch((err) => {
          console.log(err);
        });
      //get latest stats for total voltage, current, power and energy
      UserService.GetLatestDeviceStatsData(isDeviceID)
        .then((res) => {
          const { T_voltage, T_current, T_power, T_energy, temperature } =
            res.data.data.deviceData[0];
          setisStaticValue1(T_voltage);
          setisStaticValue2(T_current);
          setisStaticValue3(T_power);
          setisStaticValue4(T_energy);
          setisStaticTemperature(temperature); // temperature
        })
        .catch((err) => {
          console.log(err);
        });
      //call api for get temperature archive data data
      UserService.GetLatestDeviceTempData(isDeviceID)
        .then((res) => {
          console.log("energy daily Temp_A data:", res.data.data.deviceData[0]);
          const { Temp_A } = res.data.data.deviceData[0];
          setisStaticTemperature(Temp_A); // temperature
        })
        .catch((err) => {
          console.log(err);
        });

      io.current.emit(
        "liveGraphData",
        {
          user_id: userID,
          device_id: isDeviceID,
          objectName: "T_Energy_Hr_A",
          dataType: null,
        },
        (response) => {
          console.log(response.status, "T_power_Hr_A"); // ok
        }
      ); // sent to socket server
    }
    if (type === "monthly") {
      setisPower(false);
      setisPowerTotal(false);
      setisEnergyMonthly(true);
      setisEnergyDaily(false);
      setisEnergy(true);
      setisTemperature(false);
      setisEnergyTotal(true);
      setisEnergyPhase1(false);
      setisEnergyPhase2(false);
      setisEnergyPhase3(false);
      setisStaticTxtValue1("Frequency");
      setisStaticTxtValue2("Power Factor");
      setisStaticTxtValue3("T-Power");
      setisStaticTxtValue4("T-Energy (Monthly)");
      setisGraphLabelTxt("T-Energy-Monthly");

      UserService.GetLinkedDeviceData(isDeviceID, "T_Energy_D_A", "monthly")
        .then((res) => {
          setmonthlyenergyDataFromDB(res.data.data.deviceData);
        })
        .catch((err) => {
          console.log(err);
        });
      //get latest stats for total voltage, current, power and energy
      UserService.GetLatestDeviceStatsData(isDeviceID)
        .then((res) => {
          const { T_voltage, T_current, T_power, T_energy, temperature } =
            res.data.data.deviceData[0];
          setisStaticValue1(T_voltage);
          setisStaticValue2(T_current);
          setisStaticValue3(T_power);
          setisStaticValue4(T_energy);
          setisStaticTemperature(temperature);
        })
        .catch((err) => {
          console.log(err);
        });
      //call api for get temperature archive data data
      UserService.GetLatestDeviceTempData(isDeviceID)
        .then((res) => {
          console.log("energy daily Temp_A data:", res.data.data.deviceData[0]);
          const { Temp_A } = res.data.data.deviceData[0];
          setisStaticTemperature(Temp_A); // temperature
        })
        .catch((err) => {
          console.log(err);
        });
      io.current.emit(
        "liveGraphData",
        {
          user_id: userID,
          device_id: isDeviceID,
          objectName: "T_Energy_D_A",
          dataType: null,
        },
        (response) => {
          console.log(response.status, "T_power_A"); // ok
        }
      );
    }
    setAnchorEl(null);
  };

  const [isInitialDateData, setisInitialDateData] = useState({
    startDate: pastDate,
    endDate: today,
    minDate: pastDate,
    maxDate: today,
    drops: "down",
    opens: "left",
    applyButtonClasses: "btn-info",
    ranges: range,
    alwaysShowCalendars: true,
    locale: {
      format: "YYYY/MM/DD",
    },
  });
  useEffect(() => {
    UserService.getUserProfile(userID).then(
      (response) => {
        console.log(response);
        setstatementName(response.data.data.profile.statement_name);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [isDeviceID]);

  // start date for date range
  useEffect(() => {
    keyRef.current = Date.now();

    //console.log("!!!!!!!!!!call set start date in filter !!!!!!!!")
    if (isstartDate) {
      setisInitialDateData({
        ...isInitialDateData,
        startDate: isstartDate,
        minDate: isstartDate,
      });

      //console.log("!!!!!!!!!!call set start date in filter !!!!!!!!", isstartDate, isInitialDateData)
    }
    if (isstartDate == undefined) {
      setisInitialDateData({
        ...isInitialDateData,
        startDate: today,
        minDate: today,
      });
    }
  }, [isstartDateChange, isstartDate, isInitialDateData.startDate]);

  const io = useRef();

  useEffect(() => {
    console.log("first load");
    UserService.GetAllAccessProfile()
      .then((res) => {
        //   console.log("GetAllAccessProfile : ", res.data.data.devices);
        console.log(
          res.data.data.devices.map((x) => {
            return { id: x.id, name: x.name };
          })
        );
        setAccessProfiles(res.data.data.devices);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  //Connect socket once from server
  useEffect(() => {
    io.current = socketClient(SocketServer, connectionOptions);
    //connect
    io.current.on("connect", () => {
      // console.log("Socket connected!")
      setisSocketConnected(true);

      io.current.on("user_connected", (userIds, soketid) => {
        // console.log('----------user_connected-------- from socket:', userIds)
        // console.log(`I'm(${userIds.device_id}) connected with socket id ${soketid} from the back-end`);
      });
    });
    //disconnect
    io.current.on("disconnect", () => {
      console.log("socket disconnected");
      setisSocketConnected(false);
    });
    return () => {
      io.current.off("connect");
      io.current.off("disconnect");
      io.current.disconnect();
    };
  }, []);

  useEffect(() => {
    // console.log("!!!!!!!!!!!!!!!!!!!! is socket connectec:", isSocketConnected, io.current)
    if (isSocketConnected) {
      //socket connected
      if (isDeviceID != "" && userID != "") {
        //console.log("@@@@@@@@@@@ last open device id:", isDeviceID, userID)
        let userIds = { user_id: userID, device_id: isDeviceID };
        //console.log("user connected emit to socket data :", userIds)
        io.current.emit("user_connected", userIds);
        io.current.emit("checkDeviceStatus", { device_id: isDeviceID.trim() });
        io.current.emit("liveStatsData", {
          user_id: userID,
          device_id: isDeviceID,
          objectName: "T_power",
          dataType: null,
        });
        io.current.emit("liveGraphData", {
          user_id: userID,
          device_id: isDeviceID,
          objectName: "T_power_A",
          dataType: null,
        });
      }
    }
  }, [isSocketConnected]);

  useEffect(() => {
    //---------------------- Check device status -------------------------
    io.current.on("received_device_status_data", (data, device_id) => {
      // console.log("!!!!--- Live Device Status ---!!!!", data)
      const { device_status, device_status_timestamp_diff } = data;
      if (isDeviceID == data.device_id) {
        if (device_status == 1 && device_status_timestamp_diff <= 20) {
          setisDeviceStatus("green");
        } else if (
          device_status == 0 &&
          device_status_timestamp_diff >= 21 &&
          device_status_timestamp_diff <= 39
        ) {
          setisDeviceStatus("yellow");
        } else if (device_status == 0 && device_status_timestamp_diff >= 40) {
          //   setisDeviceStatus("red");
          //  console.log("here");
        } else if (device_status == 0 && device_status_timestamp_diff == 0) {
          setisDeviceStatus("yellow");
        } else {
          setisDeviceStatus("yellow");
        }
      }
    });
    //--------------------------- Stats Data -------------------------------------
    io.current.on("received_stats_data", (data) => {
      if (isDeviceID == data.device_id) {
        // console.log(`!!!!!!!+++++ Device stats +++++!!!!!!!`, data)

        if ((isPower && isPowerTotal) || (isEnergy && isEnergyTotal)) {
          //console.log("power total -------------")
          const { T_voltage, T_current, T_power, T_energy, temperature } = data;

          setisStaticValue1(T_voltage); // T_voltage
          setisStaticValue2(T_current); // T_current
          setisStaticValue3(T_power); // T_Power
          setisStaticValue4(T_energy); // T_Energy
          setisStaticTemperature(temperature); // temperature
        }
        if ((isPower && isPowerPhase1) || (isEnergy && isEnergyPhase1)) {
          //console.log("power phase 1")
          const {
            l1_voltage,
            l1_current,
            AP_power_l1,
            T_Energy_L1,
            temperature,
          } = data;
          setisStaticValue1(l1_voltage);
          setisStaticValue2(l1_current);
          setisStaticValue3(AP_power_l1);
          setisStaticValue4(T_Energy_L1);
          setisStaticTemperature(temperature); // temperature
        }
        if ((isPower && isPowerPhase2) || (isEnergy && isEnergyPhase2)) {
          //console.log("power phase 2")
          const {
            l2_voltage,
            l2_current,
            AP_power_l2,
            T_Energy_L2,
            temperature,
          } = data;
          setisStaticValue1(l2_voltage);
          setisStaticValue2(l2_current);
          setisStaticValue3(AP_power_l2);
          setisStaticValue4(T_Energy_L2);
          setisStaticTemperature(temperature); // temperature
        }
        if ((isPower && isPowerPhase3) || (isEnergy && isEnergyPhase3)) {
          //console.log("power phase 3")
          const {
            l3_voltage,
            l3_current,
            AP_power_l3,
            T_Energy_L3,
            temperature,
          } = data;
          setisStaticValue1(l3_voltage);
          setisStaticValue2(l3_current);
          setisStaticValue3(AP_power_l3);
          setisStaticValue4(T_Energy_L3);
          setisStaticTemperature(temperature); // temperature
        }
        //For Temperature
        if (isTemperature) {
          // console.log(
          //   "!!!!!!----- temperature stats data-------!!!!!!!!",
          //   data
          // );
          const { T_voltage, T_current, T_power, T_energy, temperature } = data;
          setisStaticValue1(T_voltage); // T_voltage
          setisStaticValue2(T_current); // T_current
          setisStaticValue3(T_power); // T_Power
          setisStaticValue4(T_energy); // T_Energy
          setisStaticTemperature(temperature); // temperature
        }
      }
    });

    //---------------------------- Graph Data -----------------------------------
    io.current.on(
      "received_graph_data",
      (data, device_id, objectName, dataType) => {
        // console.log(
        //   `!!!*** Graph data of Device ***!!!`,
        //   device_id,
        //   objectName,
        //   dataType,
        //   data
        // );
        // console.log(`!!!*** objectName ***!!!`, objectName)

        if (isDeviceID == device_id) {
          if (isPower && isPowerTotal && objectName == "T_power_A") {
            // console.log("----------- power graph total--------------")
            setIsGraphDataFromSocket(true);
            setpowerDataFromDB(data);
          }
          if (isPower && isPowerPhase1 && objectName == "L1_Power_A") {
            console.log("power graph phase 1");
            setIsGraphDataFromSocket(true);
            setpowerDataFromDB(data);
          }
          if (isPower && isPowerPhase2 && objectName == "L2_Power_A") {
            console.log("power graph phase 2");
            setIsGraphDataFromSocket(true);
            setpowerDataFromDB(data);
          }
          if (isPower && isPowerPhase3 && objectName == "L3_Power_A") {
            // console.log("power graph phase 3")
            setIsGraphDataFromSocket(true);
            setpowerDataFromDB(data);
          }
          if (isEnergy && isEnergyMonthly && objectName == "T_Energy_D_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)
            UserService.GetLinkedDeviceData(
              isDeviceID,
              "T_Energy_D_A",
              "monthly"
            )
              .then((res) => {
                setmonthlyenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });

            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyMonthly && objectName == "L1_Energy_D_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)

            UserService.GetLinkedDeviceData(
              isDeviceID,
              "L1_Energy_D_A",
              "monthly"
            )
              .then((res) => {
                setmonthlyenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyMonthly && objectName == "L2_Energy_D_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)

            UserService.GetLinkedDeviceData(
              isDeviceID,
              "L2_Energy_D_A",
              "monthly"
            )
              .then((res) => {
                setmonthlyenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyMonthly && objectName == "L3_Energy_D_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)

            UserService.GetLinkedDeviceData(
              isDeviceID,
              "L3_Energy_D_A",
              "monthly"
            )
              .then((res) => {
                setmonthlyenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyDaily && objectName == "T_Energy_Hr_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)
            UserService.GetLinkedDeviceData(
              isDeviceID,
              "T_Energy_Hr_A",
              "daily"
            ) //need to be refrshed
              .then((res) => {
                setenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });

            UserService.GetLinkedDeviceData(
              isDeviceID,
              "T_Energy_Hr_A",
              "daily"
            ) //need to be refrshed
              .then((res) => {
                setenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
                console.log(`!!!*** objectName ***!!!`, objectName);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyDaily && objectName == "L1_Energy_Hr_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)
            UserService.GetLinkedDeviceData(
              isDeviceID,
              "L1_Energy_Hr_A",
              "daily"
            )
              .then((res) => {
                setenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyDaily && objectName == "L2_Energy_Hr_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)
            UserService.GetLinkedDeviceData(
              isDeviceID,
              "L2_Energy_Hr_A",
              "daily"
            )
              .then((res) => {
                setenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          if (isEnergy && isEnergyDaily && objectName == "L3_Energy_Hr_A") {
            // console.log("power graph phase 3")
            // setIsGraphDataFromSocket(true)
            // setpowerDataFromDB(data)
            UserService.GetLinkedDeviceData(
              isDeviceID,
              "L3_Energy_Hr_A",
              "daily"
            )
              .then((res) => {
                setenergyDataFromDB(res.data.data.deviceData);
                console.log(res.data.data.deviceData);
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(`!!!*** objectName ***!!!`, objectName);
          }

          //For Temperature
          if (isTemperature) {
            console.log(
              "!!!!!!----- temperature graph data-------!!!!!!!!",
              data
            );
            const { time, temperature } = data;
            let newData = { time: time, value: temperature };
            setIstempetureDataFromSocket(true);
            settempetureDataFromSocket(data);
          }
        }
      }
    );
  }, [
    isDeviceID,
    isPower,
    isPowerTotal,
    isPowerPhase1,
    isPowerPhase2,
    isPowerPhase3,
    isEnergy,
    isEnergyTotal,
    isEnergyPhase1,
    isEnergyPhase2,
    isEnergyPhase3,
    isTemperature,
  ]);

  //socket
  // const io = socketClient(SocketServer, connectionOptions);
  // console.log("socket", io)
  // io.on('connect', () => {/[[[hh]]]
  //   console.log(`I'm connected with socket id ${io.id} from the back-end`);

  //   let userIds = { "user_id": userID, "device_id": isDeviceID };
  //   //console.log("userIds", userIds)
  //   io.emit("user_connected", userIds);

  // })

  const Schema = Yup.object().shape({
    parent_id: Yup.string().required("Select a category."),
    area_name: Yup.string().required("Area name is required.").min(3).max(15),
  });
  const AddDeviceSchemaStep1 = Yup.object().shape({
    modal_name: Yup.string().required("Select modal name!"),
  });
  const AddDeviceSchemaStep2 = Yup.object().shape({
    parent_id: Yup.string().required("Select area name!"),
    device_name: Yup.string().required("Device name field is required!"),
    device_id: Yup.string().required(
      "Please enter the device ID ( You can find the device ID on the device Label)"
    ),
  });
  const forgotDevice = Yup.object().shape({
    device_id: Yup.string().required("Please select device name"),
  });
  const deleteArea = Yup.object().shape({
    area_id: Yup.string().required("Please select area name"),
  });
  const renameArea = Yup.object().shape({
    area_id: Yup.string().required("Please select area name"),
    area_name: Yup.string().required("Area name is required!"),
  });
  const renameDevice = Yup.object().shape({
    device_id: Yup.string().required("Please select device name"),
    device_name: Yup.string().required("Device name is required!"),
  });
  const moveDevice = Yup.object().shape({
    device_id: Yup.string().required("Please select device name"),
    area_id: Yup.string().required(
      "Please select area name where you want to move!"
    ),
  });
  const addDeviceToUsers = Yup.object().shape({
    //device_id: Yup.string().required("Please select device name"),
    email: Yup.string()
      .email("Enter valid email id.")
      .required("Email id is required."),
  });
  const addVirtualDeviceSchema = Yup.object().shape({
    area_id: Yup.string().required(
      "Please select area name where you want to move!"
    ),
    device_name: Yup.string().required("Device name is required!"),
    // device_ids: Yup.array().max(2).required("Please select device name min 2 max 50"),
    // device_ids: Yup.array().min(2, "Please select device name min 2 max 50"),
  });
  const formOptions = { resolver: yupResolver(Schema) };
  const adddeviceformOptionsStep1 = {
    resolver: yupResolver(AddDeviceSchemaStep1),
  };
  const adddeviceformOptionsStep2 = {
    resolver: yupResolver(AddDeviceSchemaStep2),
  };

  const formOptionAddVirtualDevice = {
    resolver: yupResolver(addVirtualDeviceSchema),
  };

  const formOptionforgotDevice = { resolver: yupResolver(forgotDevice) };
  const formOptiondeleteArea = { resolver: yupResolver(deleteArea) };
  const formOptionRenameArea = { resolver: yupResolver(renameArea) };
  const formOptionRenameDevice = { resolver: yupResolver(renameDevice) };
  const formOptionMoveDevice = { resolver: yupResolver(moveDevice) };
  const formOptionAddUserDevice = { resolver: yupResolver(addDeviceToUsers) };
  const {
    register,
    setValue,
    formState: { errors, isSubmitting },
    handleSubmit,
    resetField,
  } = useForm(formOptions);
  const {
    register: register2,
    formState: { errors: errors2, isSubmitting: isSubmitting2 },
    handleSubmit: handleSubmit2,
    resetField: resetField2,
  } = useForm(adddeviceformOptionsStep1);
  const {
    register: register3,
    formState: { errors: errors3, isSubmitting: isSubmitting3 },
    handleSubmit: handleSubmit3,
    resetField: resetField3,
  } = useForm(adddeviceformOptionsStep2);
  const {
    register: register4,
    formState: { errors: errors4, isSubmitting: isSubmitting4 },
    handleSubmit: handleSubmit4,
    resetField: resetField4,
  } = useForm(formOptionforgotDevice);
  const {
    register: register5,
    formState: { errors: errors5, isSubmitting: isSubmitting5 },
    handleSubmit: handleSubmit5,
    resetField: resetField5,
  } = useForm(formOptiondeleteArea);
  const {
    register: register6,
    formState: { errors: errors6, isSubmitting: isSubmitting6 },
    handleSubmit: handleSubmit6,
    resetField: resetField6,
  } = useForm(formOptionRenameArea);
  const {
    register: register7,
    formState: { errors: errors7, isSubmitting: isSubmitting7 },
    handleSubmit: handleSubmit7,
    resetField: resetField7,
  } = useForm(formOptionRenameDevice);
  const {
    register: register8,
    formState: { errors: errors8, isSubmitting: isSubmitting8 },
    handleSubmit: handleSubmit8,
    resetField: resetField8,
  } = useForm(formOptionMoveDevice);
  const {
    register: register9,
    setValue: setValue9,
    formState: { errors: errors9, isSubmitting: isSubmitting9 },
    handleSubmit: handleSubmit9,
    resetField: resetField9,
  } = useForm(formOptionAddUserDevice);
  const {
    register: register10,
    formState: { errors: errors10, isSubmitting: isSubmitting10 },
    handleSubmit: handleSubmit10,
    resetField: resetField10,
  } = useForm(formOptionAddVirtualDevice);

  const callOnce = useRef(true);
  //add root user node
  useEffect(() => {
    if (callOnce.current) {
      callOnce.current = false;
      //console.log("************************call add root use **************************")
      //callOnce.current = false
      UserService.AddRootUser(userID, {
        user_name:
          user.data.profile.first_name + " " + user.data.profile.last_name,
      }).then(
        (response) => {
          //console.log("++++++++++++++++++++++++response root user+++++++++++++++++++++++++", response.data.data)
          if (response.data.type == "success") {
            console.log("added");
            setisUpdateData(response.data.data.area.id);
            //console.log(response.data.data.area)
          } else {
            //console.log("added already")
            let randomNumber = Math.random() * 10000;
            setisUpdateData(randomNumber);
          }
        },
        (error) => {
          //{ error && toast.error(error.response.data.message, { toastId: 2603453643 }) }
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  }, [user, userID]);
  //fetch category data
  useEffect(() => {
    UserService.GetTreeViewCategory(userID).then(
      (response) => {
        setContent(response.data.data.profile);
        //console.log("response tree view data", response.data.data.profile)
      },
      (error) => {
        {
          error &&
            toast.error(error.response.data.message, { toastId: 2603453643 });
        }
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  }, [isLoading, isUpdateData]);

  //fetch device id data
  useEffect(() => {
    UserService.GetAddedDevices(userID).then(
      (response) => {
        setContentDevice(response.data.data.profile);
        //console.log("response device data ---", response.data.data.profile)
      },
      (error) => {
        {
          error &&
            toast.error(error.response.data.message, { toastId: 2603453643 });
        }
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setContentDevice(_content);
      }
    );
  }, [isGetDeviceLoading, isUpdateData]);

  //fetch area name data
  useEffect(() => {
    UserService.GetAddedAreas(userID).then(
      (response) => {
        setContentArea(response.data.data.profile);
        // console.log("response device data ---", response.data.data.profile)
      },
      (error) => {
        {
          error &&
            toast.error(error.response.data.message, { toastId: 2603453643 });
        }
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setContentArea(_content);
      }
    );
  }, [isLoading, isAddDeviceLoading, isUpdateData, isGetDeviceLoading]);

  //fetch tree view data
  useEffect(() => {
    UserService.GetTreeViewData(userID).then(
      (response) => {
        //console.log("tree view data0000000000000000000000", response.data.data.profile)
        setTreeViewData(response.data.data.profile);
        //console.log("response", response.data.data.profile)
      },
      (error) => {
        {
          error &&
            toast.error(error.response.data.message, { toastId: 2603453643 });
        }
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setTreeViewData(_content);
      }
    );
  }, [isLoading, isAddDeviceLoading, isUpdateData, isGetDeviceLoading]);

  //fetch category data
  useEffect(() => {
    //console.log("########### call added device id function ##################")
    UserService.GetAddedDevices(userID).then(
      (response) => {
        setContentDevice(response.data.data.profile);
        //console.log("response device data ---", response.data.data.profile)
      },
      (error) => {
        {
          error &&
            toast.error(error.response.data.message, { toastId: 2603453643 });
        }
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setContentDevice(_content);
      }
    );
  }, [isGetDeviceLoading, isUpdateData]);
  //fetch
  useEffect(() => {
    // let locations = []
    // Object.values(treeViewData).map(item => {
    //   //.log("first", item)
    //   let newdata = { ...item, key: item.label };
    //   locations.push(newdata)
    // })

    async function createTreeView(location) {
      if (location != undefined) {
        var tree = [],
          object = {},
          parent,
          child;
        for (var i = 0; i < location.length; i++) {
          parent = location[i];
          object[parent.id] = parent;
          object[parent.id]["children"] = [];
        }
        for (var id in object) {
          if (object.hasOwnProperty(id)) {
            child = object[id];
            if (child.parent_id && object[child["parent_id"]]) {
              //delete child.id
              //
              object[child["parent_id"]]["children"].push(child);
              delete child.parent_id;
            } else {
              //delete child.id
              delete child.parent_id;
              tree.push(child);
            }
          }
        }
        return tree;
      }
    }
    createTreeView(treeViewData[0]).then((data) => {
      console.log("treeViewData[0] ------------------", treeViewData[0]);
      console.log("tree view final data ------------------", data);
      if (data != undefined) {
        console.log("tree view final data *************************", data[0]);
        setRootTreeViewData(data[0]);
      }
    });
  }, [treeViewData]);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <NodeWithContextMenu
          label={nodes.label}
          id={nodes.id}
          is_type={nodes.is_type}
          device_id={nodes.device_id}
          shared_by={nodes.shared_by}
          is_shared_device={nodes.is_shared_device}
          profile={nodes.profile}
        />
      }
      icon={
        nodes.is_type == "device" ? (
          <Devices
            sx={{
              color: `${nodes.is_shared_device == "true" ? "orange" : "#1d9b9c"
                }`,
            }}
          />
        ) : nodes.is_type == "virtual_device" ||
          nodes.is_type == "Virtual_moroco" ? (
          <WorkspacesIcon
            sx={{
              color: "green",
            }}
          />
        ) : null
      }
    >
      {nodes.is_type !== "virtual_device" && Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  //submit handler
  const onSubmit = (formValue) => {
    //console.log(formValue)
    //return false
    setisLoading(true);
    UserService.AddNewArea(userID, formValue)
      .then(() => {
        setisLoading(false);
        ///localStorage.setItem("user", JSON.stringify(updateUserData));
        toast.success("Area successfully Added.", { toastId: 23453643 });
        resetField("area_name");
      })
      .catch((error) => {
        setisLoading(false);
        {
          error &&
            toast.info(error.response.data.message, {
              toastId: 234536467686787,
            });
        }
      });
  };
  //forgot device submit
  const onSubmitForgotDevice = (formValue) => {
    //return false
    if (formValue.device_id != undefined) {
      Swal.fire({
        title: "Are you sure?",
        text: "To forgot the device!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, forgot it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setforgotisLoading(true);
          UserService.forgotDeviceID(formValue.device_id)
            .then((res) => {
              console.log("forgot device res--", res);
              if (res.data.data.error == false) {
                toast.success("Device successfully forgot.", {
                  toastId: 3446467686787,
                });
                setContentDevice(res.data.data.updatedId);
                setisAddDeviceLoading(res.data.data.updatedId);
                setforgotisLoading(false);
                setIsForgotDevice(false);
                setIsAddDevice(false);
                setIsAddArea(false);
                setshowGraph(false);
                setIsVirtualDevice(false);
                setshowWelcomeDiv(true);
                window.location.reload();
              } else {
                toast.error("Please select a device!", {
                  toastId: 234534464676867878,
                });
                setforgotisLoading(false);
              }
            })
            .catch((error) => {
              setisLoading(false);
              {
                error &&
                  toast.info(error.response.data.message, {
                    toastId: 234536467686787,
                  });
              }
            });
          setforgotisLoading(false);
        } else {
          setforgotisLoading(false);
        }
      });
    } else {
      toast.info("Please select device name", { toastId: 2345366467686787 });
    }
  };
  //delete area submit
  const onSubmitDeleteArea = (formValue) => {
    //return false
    if (formValue.area_id != undefined) {
      Swal.fire({
        title: "Are you sure ?",
        text: "want to delete this area!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setdeleteAreaisLoading(true);
          UserService.deleteAreaName(formValue.area_id, userID)
            .then((res) => {
              console.log("Delete area API res--", res);

              if (res.data.data.error == false) {
                toast.success("Area successfully deleted!", {
                  toastId: 4464676867878,
                });
                setisUpdateData(res.data.data.updatedId);
                setdeleteAreaisLoading(false);
                window.location.reload();
              } else if (res.data.data.error == "not_found") {
                toast.error("Refresh page and then select a area!", {
                  toastId: 4488676867878,
                });
                setisUpdateData(res.data.data.updatedId);
                setdeleteAreaisLoading(false);
              } else {
                Swal.fire({
                  title: "Are you want to sure ?",
                  text: res.data.data.msg,
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setdeleteAreaisLoading(true);
                    //delete all area and devices in it
                    UserService.deleteAllAreasandDevices(res.data.data.ids)
                      .then((res) => {
                        //console.log("ressss", res)
                        toast.success("Area successfully deleted!", {
                          toastId: 4564676867878,
                        });
                        window.location.reload();
                        setisUpdateData(res.data.data.updatedId);
                        setdeleteAreaisLoading(false);
                      })
                      .catch((err) => {
                        console.log(err);
                        setdeleteAreaisLoading(false);
                      });
                  } else {
                    setdeleteAreaisLoading(false);
                  }
                });
                setdeleteAreaisLoading(false);
              }
            })
            .catch((error) => {
              setisLoading(false);
              console.log(error);
              {
                error &&
                  toast.info(error.response.data.message, {
                    toastId: 234536467686787,
                  });
              }
            });
          setdeleteAreaisLoading(false);
        } else {
          setdeleteAreaisLoading(false);
        }
      });
    } else {
      toast.info("Please select device name", { toastId: 2345366467686787 });
    }
  };
  //rename area
  const onSubmitRenameArea = (formValue) => {
    //return false
    const { area_id, area_name } = formValue;
    if (area_id != undefined && area_name != undefined) {
      Swal.fire({
        title: "Are you sure ?",
        text: "want to rename this area!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Rename it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setrenameAreaisLoading(true);
          UserService.editAreaName(area_id, area_name)
            .then((res) => {
              if (res.data.data.error == false) {
                toast.success("Area successfully renamed!", {
                  toastId: 4494676867878,
                });
                setisUpdateData(res.data.data.updatedId);
                setrenameAreaisLoading(false);
              }
              // if (res.data.data.error == "not_found") {
              //   toast.error('Refresh page and then select a area!', { toastId: 4488676867878 })
              //   setisUpdateData(res.data.data.updatedId)
              //   setrenameAreaisLoading(false)
              // } else {
              //   Swal.fire({
              //     title: 'Are you want to sure ?',
              //     text: res.data.data.msg,
              //     icon: 'question',
              //     showCancelButton: true,
              //     confirmButtonColor: '#3085d6',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: 'Yes, Delete it!'
              //   }).then((result) => {
              //     if (result.isConfirmed) {
              //       setrenameAreaisLoading(true)
              //       //delete all area and devices in it
              //       UserService.deleteAllAreasandDevices(res.data.data.ids)
              //         .then((res) => {
              //           console.log("ressss", res)
              //           toast.success('Area successfully deleted!', { toastId: 4564676867878 })
              //           setisUpdateData(res.data.data.updatedId)
              //           setrenameAreaisLoading(false)
              //         }).catch(err => {
              //           console.log(err)
              //           setrenameAreaisLoading(false)
              //         })
              //     } else {
              //       setrenameAreaisLoading(false)
              //     }
              //   })
              //   setrenameAreaisLoading(false)

              // }
            })
            .catch((error) => {
              setisLoading(false);
              console.log(error);
              {
                error &&
                  toast.info(error.response.data.message, {
                    toastId: 234736467686787,
                  });
              }
            });
          setrenameAreaisLoading(false);
        } else {
          setrenameAreaisLoading(false);
        }
      });
    } else {
      toast.info("Please select device name", { toastId: 2345366467686787 });
    }
  };
  //rename device
  const onSubmitRenameDevice = (formValue) => {
    console.log(formValue);
    //return false
    if (formValue.device_id != undefined) {
      Swal.fire({
        title: "Are you sure ?",
        text: "want to rename this device!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Rename it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setdeleteAreaisLoading(true);
          UserService.editDeviceName(formValue.device_id, formValue.device_name)
            .then((res) => {
              if (res.data.data.error == false) {
                toast.success("Device successfully renamed!", {
                  toastId: 1464676867878,
                });
                setisUpdateData(res.data.data.updatedId);
                setdeleteAreaisLoading(false);
              } else {
                toast.success(
                  "Internal server error, please try after sone time!",
                  { toastId: 1464976867878 }
                );
                setisUpdateData(res.data.data.updatedId);
                setdeleteAreaisLoading(false);
              }
            })
            .catch((error) => {
              setisLoading(false);
              console.log(error);
              {
                error &&
                  toast.info(error.response.data.message, {
                    toastId: 234536467686787,
                  });
              }
            });
          setdeleteAreaisLoading(false);
        } else {
          setdeleteAreaisLoading(false);
        }
      });
    } else {
      toast.info("Please select device name", { toastId: 2345366467686787 });
    }
  };
  //move devices
  const onSubmitMoveDevice = (formValue) => {
    //return false
    if (formValue.area_id != undefined) {
      Swal.fire({
        title: "Are you sure ?",
        text: "want to move this device!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Move it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setdeleteAreaisLoading(true);
          UserService.moveDevices(
            formValue.device_id,
            formValue.area_id,
            userID
          )
            .then((res) => {
              if (res.data.data.error == false) {
                toast.success("Device successfully moved!", {
                  toastId: 4464676867878,
                });
                setisUpdateData(res.data.data.updatedId);
                setdeleteAreaisLoading(false);
              } else {
                toast.error(
                  "Internal server error, please try after some time!",
                  { toastId: 4164676867878 }
                );
              }
            })
            .catch((error) => {
              setisLoading(false);
              console.log(error);
              {
                error &&
                  toast.info(error.response.data.message, {
                    toastId: 234536467686787,
                  });
              }
            });
          setdeleteAreaisLoading(false);
        } else {
          setdeleteAreaisLoading(false);
        }
      });
    } else {
      toast.info("Please select device name", { toastId: 2345366467686787 });
    }
  };
  //Add device to users
  const onSubmitaddDeviceToUser = (formValue) => {
    //return false
    const { email, user_id, device_id, device_name, profile } = formValue;
    Swal.fire({
      title: "Are you sure ?",
      text: "want to add this device!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setisaddDeviceToUser(true);
        let data = {
          user_id,
          device_id,
          device_name,
          profile: parseInt(profile),
        };
        UserService.assignDeviceTousers(email, data)
          .then((res) => {
            setisaddDeviceToUser(false);
            if (res.data.message === "Not_found") {
              toast.info(
                "This user is not registered with us,sent a email to sign our plateform!",
                { toastId: 4524 }
              );
            }
            if (res.data.message === "successfully_added_shared_device") {
              toast.success("Device successfully shared.", { toastId: 4224 });
            }
            if (res.data.message === "already_added_shared_device") {
              toast.info("Device already shared.", { toastId: 4024 });
            }

            // if (res.data.data.error == false) {
            //   toast.success('Device successfully moved!', { toastId: 4464676867878 })
            //   setisUpdateData(res.data.data.updatedId)
            //   setisaddDeviceToUser(false)
            // } else {
            //   toast.error('Internal server error, please try after some time!', { toastId: 4164676867878 })
            // }
          })
          .catch((error) => {
            setisaddDeviceToUser(false);
            console.log(error);
            {
              error &&
                toast.info(error.response.data.message, {
                  toastId: 234536467686787,
                });
            }
          });
      } else {
        setisaddDeviceToUser(false);
      }
    });
  };

  const onSubmitStepOne = (formValue) => {
    setstepOne(false);
    setstepTwo(true);
    console.log(formValue);
    if (
      formValue.modal_name === "virtual_device" ||
      formValue.modal_name === "Virtual_moroco"
    ) {
      setIsVirtualDeviceAdd(true);
      setVirtualDeviceType(formValue.modal_name);
    } else setIsVirtualDeviceAdd(false);
    return false;
  };
  const onSubmitSteptwo = (formValues) => {
    // console.log("form value", formValues)

    const {
      parent_id,
      device_name: deviceName,
      device_id: deviceid,
    } = formValues;
    let device_name = deviceName.trim();
    let deviceID = deviceid.trim();
    let formValue = {
      parent_id,
      device_name,
      device_id: deviceID,
      modal_name: virtualDeviceType,
    };
    console.log("form value", formValue);

    //return false
    setstepTwoisLoading(true);
    //--------------------------------- Api for check valid device using device ID -------------------------
    UserService.checkDeviceID(deviceID)
      .then((res) => {
        setstepTwoisLoading(false);
        if (res.data.data.error) {
          //device id not found
          toast.error(
            "The device ID entered is incorrect. Please re-enter the device ID ",
            { toastId: 2345363333343 }
          );
          setAddDeviceBtnText("Retry");
        } else {
          //1. ************************ found a valid device next check device is online/offline *******************
          console.log("call API for check online/offline");
          UserService.checkDeviceOnlineStatus(deviceID)
            .then((result) => {
              console.log(result);
              console.log("result----------", result);
              if (result.data.data.error) {
                toast.error(
                  "The device ID entered is incorrect. Please re-enter the device ID ",
                  { toastId: 2145363333343 }
                );
                setAddDeviceBtnText("Retry");
              } else {
                console.log("data==", result.data.data[0].device_status);
                let deviceStatus = result.data.data[0].device_status; //1=online,o=offline,2=not connected
                if (deviceStatus === 1) {
                  //online
                  Swal.fire({
                    title: "The device is detected online",
                    text: "To link the device, Select Device-link button. To exit the device link procedure select Exit button ( Please note that if you link the device to this user account, it will be disconnected from any other user account, Are you sure to link device?",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Device Link",
                    cancelButtonText: "Exit",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log(
                        "call api for check already added device id for same user"
                      );
                      UserService.checkAlreadyAddedDevice(userID, deviceID)
                        .then((res) => {
                          console.log(
                            "check already added device id for same user res--",
                            res.data.data.error
                          );
                          if (res.data.data.error != false) {
                            //not exists device id
                            console.log("not exixts");
                            console.log(
                              "call api for remove assign device if exists"
                            );
                            //remove associated user device
                            UserService.removeAssignDeviceID(deviceID)
                              .then((resultData) => {
                                console.log(
                                  "resultData=======",
                                  resultData.data
                                );
                                let timerInterval;
                                Swal.fire({
                                  title:
                                    "Please press the Device link combination buttons at the device Terminal.",
                                  html: "It will be close in <b></b> seconds.",
                                  icon: "info",
                                  timer: 90000,
                                  timerProgressBar: true,
                                  showCancelButton: true,
                                  cancelButtonColor: "#d33",
                                  cancelButtonText: "Exit",
                                  allowOutsideClick: false,
                                  allowEscapeKey: false,
                                  didOpen: () => {
                                    Swal.showLoading();
                                    timerInterval = setInterval(() => {
                                      Swal.getHtmlContainer().querySelector(
                                        "b"
                                      ).textContent = (
                                        Swal.getTimerLeft() / 1000
                                      ).toFixed(0);
                                    }, 100);
                                  },
                                  willClose: () => {
                                    Swal.fire(
                                      "Device Link failed. Please again!.",
                                      "",
                                      "error"
                                    );
                                    clearInterval(timerInterval);
                                    clearInterval(interval);
                                  },
                                });
                                //check for Link_Cfm value 50 to 125 every second
                                console.log("device link api call");

                                const interval = setInterval(() => {
                                  UserService.checkDeviceLinkValue(deviceID)
                                    .then((linkVal) => {
                                      console.log(
                                        "linkVal.data.error----",
                                        linkVal.data.error
                                      );
                                      if (linkVal.data.error == false) {
                                        console.log(
                                          "linkVal.data.data[0]--------------------------",
                                          linkVal.data.data[0]
                                        );
                                        let checkLinkVal =
                                          linkVal.data.data[0].link_cfm_updated;
                                        let isConnectedDevice =
                                          linkVal.data.data[0].is_connected;
                                        console.log(
                                          "checkLinkVal-=======",
                                          checkLinkVal
                                        );
                                        if (checkLinkVal == "true") {
                                          //Added a new device to area
                                          console.log(
                                            "Call api for add device finally when not assign to any device"
                                          );
                                          UserService.AddNewDevice(
                                            userID,
                                            formValue
                                          )
                                            .then((resData) => {
                                              console.log(
                                                resData.data.data.area.id
                                              );
                                              setisAddDeviceLoading(
                                                resData.data.data.area.id
                                              );
                                              setisgetDeviceLoading(
                                                resData.data.data.area.id
                                              );
                                              Swal.fire(
                                                "Device-link is completed successfully.",
                                                "",
                                                "success"
                                              );
                                              clearInterval(timerInterval);
                                              toast.success(
                                                "Device successfully Added.",
                                                { toastId: 2345353643 }
                                              );
                                              resetField("parent_id");
                                              resetField("device_name");
                                              resetField("device_id");
                                              clearInterval(interval);
                                              UserService.UpdateDeviceConState(
                                                deviceID,
                                                {
                                                  is_connected: 1,
                                                  device_status_for_iot_gateway: 77,
                                                  link_cfm_updated: "false",
                                                }
                                              )
                                                .then((ress) => {
                                                  console.log(
                                                    "is_connected res--",
                                                    ress
                                                  );
                                                })
                                                .catch((err) =>
                                                  console.log(
                                                    "is connected err--",
                                                    err
                                                  )
                                                );
                                            })
                                            .catch((error) => {
                                              setisAddDeviceLoading(false);
                                              {
                                                error &&
                                                  toast.info(
                                                    error.response.data.message,
                                                    { toastId: 234536467686787 }
                                                  );
                                              }
                                            });
                                        } else {
                                          console.log("NOOOOOOOOOOOOOOOOO");
                                        }
                                      }
                                    })
                                    .catch((linkErr) => {
                                      console.log(linkErr);
                                    });
                                }, 2000);
                              })
                              .catch((errorData) => {
                                console.log("errorData", errorData);
                              });
                          } else {
                            Swal.fire(
                              "Device is already connected.",
                              "",
                              "warning"
                            );
                          }
                        })
                        .catch((err) => console.log(err));
                    }
                  });
                } else if (deviceStatus === 0) {
                  //offline
                  setAddDeviceBtnText("Refresh");
                  toast.info(
                    "The device is offline. Make sure the device is connected to the network.",
                    { toastId: 23 }
                  );
                } else {
                  //offline and not connected
                  setAddDeviceBtnText("Refresh");
                  toast.info(
                    "The device is offline. Make sure the device is connected to the network.",
                    { toastId: 2398765e4 }
                  );
                }
              }
            })
            .catch((errorLog) => {
              setAddDeviceBtnText("Verify");
              console.log(errorLog);
            });
        }
      })
      .catch((error) => {
        setstepTwoisLoading(false);
        {
          error &&
            toast.info(error.response.data.message, { toastId: 3424213 });
        }
        setAddDeviceBtnText("Verify");
      });
  };

  const onSubmitAddVirtualDevice = (formValues) => {
    console.log(formValues);
    const { area_id, device_ids, modal_name, device_name } = formValues;
    // device_name = device_name.trim();
    let formValue = {
      parent_id: area_id,
      device_name: device_name.trim(),
      modal_name: virtualDeviceType,
    };
    console.log("form value", formValue);
    //create a new record in device and device link
    UserService.createVIrtualDevice(userID, formValue)
      .then((resData) => {
        if (resData.data.type === "success") {
          console.log(resData);

          toast.success("Device successfully Added.", { toastId: 2345353643 });
          setisUpdateData(resData.data.data);

          resetField10("area_id");
          resetField10("device_ids");
          resetField10("device_name");
        } else {
          toast.error("Device not created.", { toastId: 2345353643 });
        }
      })
      .catch((error) => {
        {
          error &&
            toast.info(error.response.data.message, {
              toastId: 234536467686787,
            });
        }
      });
  };

  let oldOptionTemplate = Object.values(content).map((v, i) =>
    i == 0 ? (
      <option value={userID}>New Area</option>
    ) : (
      <option value={v.id}>{v.label}</option>
    )
  );

  let optionTemplate = Object.values(content)
    .filter((x) => x.device_id == "0")
    .filter((x) => x.is_type == "area" || x.is_type == "user")
    .sort(function (b, a) {
      return b.id - a.id;
    })
    .map((v, i) =>
      i == 0 ? (
        <option value={v.id}>New Area</option>
      ) : (
        <option value={v.id}>{v.label}</option>
      )
    );

  let addedDevices = Object.values(contentDevice)
    .filter(
      (x) =>
        x.is_type == "device" ||
        x.is_type == "virtual_device" ||
        x.is_type == "Virtual_moroco"
    )
    .map((v, i) => (
      // <option value={v.id} selected={renameDeleteId === v.id}>{v.label}</option>
      <option value={v.id}>{v.label}</option>
    ));

  let addedDevicesId = Object.values(contentDevice).map((v, i) => (
    // <option value={v.id} selected={renameDeleteId === v.id}>{v.label}</option>
    <option value={v.device_id}>{v.label}</option>
  ));
  // console.log(Object.values(contentArea))
  let addedAreas = Object.values(contentArea)
    .filter((x) => x.is_type === "area")
    .map((v, i) => (
      <option value={v.id} selected={renameDeleteId === v.id}>
        {v.label}
      </option>
    ));
  // console.log(process.env)
  return (
    <div>
      <Header />
      {/* MAin Navigation END    */}

      <section className="main-slider">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-sm-12 left-device">
              <div className>
                <div id="left" className="span3">
                  {/* <Tooltip title="Right click to edit" placement="left">
                    <HelpOutlineOutlined className="help-icon" />
                  </Tooltip> */}
                  <TreeView
                    //className={classes.root}
                    aria-label="rich object"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpanded={["root"]}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{
                      height: "100%",
                      flexGrow: 1,
                      maxWidth: 500,
                      overflowY: "auto",
                      color: "white",
                      fontWeight: 20,
                    }}
                  >
                    {renderTree(rootTreeViewData)}
                  </TreeView>
                  <br />
                  <Stack className="add-btn" spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="success"
                      className={`${isAddDevice && isActiveBtn ? "active" : null
                        }`}
                      style={{
                        borderRadius: 25,
                        backgroundColor: "rgb(51, 164, 165)",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setIsAddDevice(true);
                        setIsRenameArea(false);
                        setIsRenameDevice(false);
                        setIsDeleteArea(false);
                        setIsAddArea(false);
                        setshowGraph(false);
                        setIsVirtualDevice(false);
                        setshowWelcomeDiv(false);
                        setIsForgotDevice(false);
                        setIsActiveBtn(true);
                        setIsVirtualDevice(false);
                      }}
                    >
                      Add Device
                    </Button>
                    <Button
                      variant="contained"
                      className={`${isAddArea && isActiveBtn ? "active" : null
                        }`}
                      style={{
                        borderRadius: 25,
                        backgroundColor: "rgb(51, 164, 165)",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setIsAddArea(true);
                        setIsRenameArea(false);
                        setIsRenameDevice(false);
                        setIsDeleteArea(false);
                        setIsAddDevice(false);
                        setshowWelcomeDiv(false);
                        setIsForgotDevice(false);
                        setIsActiveBtn(true);
                        setIsVirtualDevice(false);
                      }}
                    >
                      Add New Area
                    </Button>
                  </Stack>
                  {/* <Stack className="add-btn" spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="success"
                      className={`${
                        isAddVirtualDevice && isActiveBtn ? "active" : null
                      }`}
                      style={{
                        "margin-top": "5px",
                        borderRadius: 25,
                        backgroundColor: "rgb(51, 164, 165)",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        // setIsAddVirtualDevice(true);
                        setIsAddDevice(false);
                        setIsRenameArea(false);
                        setIsRenameDevice(false);
                        setIsDeleteArea(false);
                        setIsAddArea(false);
                        setshowGraph(false);setIsVirtualDevice(false);
                        setshowWelcomeDiv(false);
                        setIsForgotDevice(false);
                        setIsActiveBtn(true);
                      }}
                    >
                      Add Virtual Device
                    </Button>
                  </Stack> */}
                  <div className="btn-group">
                    {/* <button type="button" class="btn-info btn-sm" onClick={() => {
                      setIsAddDevice(true)
                      setIsRenameArea(false)
                      setIsRenameDevice(false)
                      setIsDeleteArea(false)
                      setIsAddArea(false)
                      setshowGraph(false)
                      setshowWelcomeDiv(false)
                      setIsForgotDevice(false)
                    }}> Add Device</button>
                    <button type="button" class="btn-primary btn-sm" onClick={() => {
                      setIsAddArea(true)
                      setIsRenameArea(false)
                      setIsRenameDevice(false)
                      setIsDeleteArea(false)
                      setIsAddDevice(false)
                      setshowWelcomeDiv(false)
                      setIsForgotDevice(false)
                    }}> Add New Area</button> */}
                    {/* <button type="button" class="btn-primary btn-sm" onClick={() => {
                      setIsRenameArea(true)
                      setIsRenameDevice(false)
                      setIsDeleteArea(false)
                      setIsForgotDevice(false)
                      setIsAddDevice(false)
                      setIsAddArea(false)
                      setshowGraph(false)
                      setshowWelcomeDiv(false)
                    }}> Rename Areas</button>
                    <button type="button" class="btn-danger btn-sm" onClick={() => {
                      setIsForgotDevice(true)
                      setIsRenameArea(false)
                      setIsRenameDevice(false)
                      setIsDeleteArea(false)
                      setIsAddDevice(false)
                      setIsAddArea(false)
                      setshowGraph(false)
                      setshowWelcomeDiv(false)
                    }}> Delete Devices</button>
                    <button type="button" class="btn-danger btn-sm" onClick={() => {
                      setIsDeleteArea(true)
                      setIsRenameArea(false)
                      setIsRenameDevice(false)
                      setIsForgotDevice(false)
                      setIsAddDevice(false)
                      setIsAddArea(false)
                      setshowGraph(false)
                      setshowWelcomeDiv(false)
                    }}> Delete Areas</button>
                    <button type="button" class="btn-primary btn-sm" onClick={() => {
                      setIsRenameDevice(true)
                      setIsRenameArea(false)
                      setIsDeleteArea(false)
                      setIsForgotDevice(false)
                      setIsAddDevice(false)
                      setIsAddArea(false)
                      setshowGraph(false)
                      setshowWelcomeDiv(false)
                    }}> Rename Device</button>
                    <button type="button" class="btn-info btn-sm"
                    // onClick={() => {
                    //   setIsRenameDevice(true)
                    //   setIsRenameArea(false)
                    //   setIsDeleteArea(false)
                    //   setIsForgotDevice(false)
                    //   setIsAddDevice(false)
                    //   setIsAddArea(false)
                    //   setshowGraph(false)
                    //   setshowWelcomeDiv(false)
                    // }}
                    >Move Devices</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  {isforgotdDevice ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Forgot Device
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit4(
                                      onSubmitForgotDevice
                                    )}
                                  >
                                    <div className="form-group">
                                      <select
                                        {...register4("device_id")}
                                        className={`form-control ${errors4.device_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Device Name
                                          ---------------------------
                                        </option>
                                        {addedDevices}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors4.device_id?.message}
                                      </span>
                                    </div>
                                    {forgotisLoading ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting4}
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {isDeleteArea ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Delete Area
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit5(onSubmitDeleteArea)}
                                  >
                                    <div className="form-group">
                                      <select
                                        {...register5("area_id")}
                                        className={`form-control ${errors5.area_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Area Name ---------------------------
                                        </option>
                                        {addedAreas}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors5.area_id?.message}
                                      </span>
                                    </div>
                                    {deleteAreaisLoading ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting5}
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {isRenameArea ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Rename Area
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit6(onSubmitRenameArea)}
                                  >
                                    <div className="form-group">
                                      <select
                                        {...register6("area_id")}
                                        className={`form-control ${errors6.area_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Area Name ---------------------------
                                        </option>
                                        {addedAreas}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors6.area_id?.message}
                                      </span>
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        {...register6("area_name")}
                                        placeholder="Enter area name"
                                        className={`form-control ${errors6.area_name ? "is-invalid" : ""
                                          }`}
                                        autoComplete="off"
                                      />
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors6.area_name?.message}
                                      </span>
                                    </div>
                                    {renameAreaisLoading ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting6}
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {isRenameDevice ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Rename Device
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit7(
                                      onSubmitRenameDevice
                                    )}
                                  >
                                    <div className="form-group">
                                      <select
                                        {...register7("device_id")}
                                        className={`form-control ${errors7.device_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Device Name
                                          ---------------------------
                                        </option>
                                        {addedDevices}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors7.device_id?.message}
                                      </span>
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        {...register7("device_name")}
                                        placeholder="Enter device name"
                                        className={`form-control ${errors7.device_name
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        autoComplete="off"
                                      />
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors7.device_name?.message}
                                      </span>
                                    </div>
                                    {deleteAreaisLoading ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting7}
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {isMoveDevice ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Move Device
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit8(onSubmitMoveDevice)}
                                  >
                                    <div className="form-group">
                                      <select
                                        {...register8("device_id")}
                                        className={`form-control ${errors8.device_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Device Name
                                          ---------------------------
                                        </option>
                                        {addedDevices}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors8.device_id?.message}
                                      </span>
                                    </div>
                                    <div className="form-group">
                                      <select
                                        {...register8("area_id")}
                                        className={`form-control ${errors8.area_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Area Name ---------------------------
                                        </option>
                                        {addedAreas}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors8.area_id?.message}
                                      </span>
                                    </div>

                                    {deleteAreaisLoading ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting7}
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {isAddDeviceTouser ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <div className="col-lg-12 col-sm-12">
                                    {/* <ManageAccessProfiles
                                    ></ManageAccessProfiles> */}
                                  </div>
                                  <div className="col-lg-12 col-sm-12">
                                    <SharedList
                                      clickedDevice={clickedDevice}
                                      acessProfiles={acessProfiles}
                                    ></SharedList>
                                  </div>
                                  <h4 className="text-uppercase text-center">
                                    Add Device To User
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit9(
                                      onSubmitaddDeviceToUser
                                    )}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="hidden"
                                        {...register9("device_name")}
                                      />
                                      <input
                                        type="hidden"
                                        {...register9("user_id")}
                                      />
                                      <input
                                        type="hidden"
                                        {...register9("device_id")}
                                      />
                                      <input
                                        type="text"
                                        {...register9("email")}
                                        className={`form-control ${errors9.email ? "is-invalid" : ""
                                          }`}
                                        placeholder="Enter User Email ID"
                                      />

                                      <select
                                        {...register9("profile")}
                                        className={`form-control ${errors9.profile ? "is-invalid" : ""
                                          }`}
                                        placeholder="Enter a profile"
                                      >
                                        {/* <option value="0">Agile</option>
                                        <option value="1">Odascoo</option>
                                        <option value="2">Orrange</option> */}
                                        {acessProfiles.map((x) => (
                                          <option value={parseInt(x.id)}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>

                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors9.email?.message}
                                      </span>
                                    </div>
                                    {isaddDeviceToUser ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting9}
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {/* ---------------------- Add new device section ------------------------- */}

                  {/* Step 1 */}
                  {isAddDevice ? (
                    stepOne ? (
                      <div className="welcome_wraper" id="step1">
                        <div className="section-heading text-center">
                          <section className="login_wraper">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                  <div className="contact-form2">
                                    <h4 className="text-uppercase text-center">
                                      Add Device (Step 1)
                                    </h4>
                                    <form
                                      onSubmit={handleSubmit2(onSubmitStepOne)}
                                    >
                                      <div className="form-group">
                                        <select
                                          {...register2("modal_name")}
                                          className={`form-control ${errors2.modal_name
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        >
                                          <option value="">
                                            --------------------------- Select
                                            Modal ---------------------------
                                          </option>
                                          <option value="IEL - 300 V1">
                                            IEL - 300 V1
                                          </option>

                                          <option value="IEL - 300 V1">
                                            IEL-DC{" "}
                                          </option>
                                          <option value="IEL - MOR">
                                            IEL-MOR{" "}
                                          </option>
                                          <option value="IEL - MOR2">
                                            IEL-MOR2{" "}
                                          </option>
                                          <option value="IEL - 300 V1">
                                            AHS{" "}
                                          </option>
                                          <option value="virtual_device">
                                            Virtual IEL - 300 V1
                                          </option>
                                          <option value="Virtual_moroco">
                                            Virtual Morocco -
                                          </option>
                                        </select>
                                        <span
                                          style={{
                                            color: "red",
                                            float: "left",
                                          }}
                                        >
                                          {errors2.modal_name?.message}
                                        </span>
                                      </div>
                                      <button
                                        type="submit"
                                        style={{ borderRadius: 25, margin: 10 }}
                                        className="btn btn-primary"
                                        disabled={isSubmitting2}
                                      >
                                        Next
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    ) : stepTwo ? (
                      <>
                        {isVirtualDeviceAdd ? (
                          <div className="welcome_wraper" id="step2">
                            <div className="section-heading text-center">
                              <section className="login_wraper">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                      <div className="contact-form2">
                                        <h4 className="text-uppercase text-center">
                                          Add Virtual Device
                                        </h4>
                                        <form
                                          onSubmit={handleSubmit10(
                                            onSubmitAddVirtualDevice
                                          )}
                                        >
                                          <div className="form-group">
                                            <select
                                              {...register10("area_id")}
                                              placeholder="Select Area"
                                              className={`form-control ${errors10.parent_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            >
                                              {addedAreas}
                                            </select>
                                            <span
                                              style={{
                                                color: "red",
                                                float: "left",
                                              }}
                                            >
                                              {errors10.parent_id?.message}
                                            </span>
                                          </div>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              {...register10("device_name")}
                                              placeholder="Please enter device name"
                                              className={`form-control ${errors10.device_name
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                            <span
                                              style={{
                                                color: "red",
                                                float: "left",
                                              }}
                                            >
                                              {errors10.device_name?.message}
                                            </span>
                                          </div>
                                          <button
                                            type="button"
                                            style={{
                                              borderRadius: 25,
                                              margin: 10,
                                            }}
                                            className="btn btn-info"
                                            onClick={() => {
                                              setstepOne(true);
                                              setstepTwo(false);
                                            }}
                                          >
                                            Exit
                                          </button>
                                          <button
                                            type="submit"
                                            style={{
                                              borderRadius: 25,
                                              margin: 10,
                                            }}
                                            className="btn btn-primary"
                                            disabled={isSubmitting3}
                                          >
                                            Apply
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        ) : (
                          <div className="welcome_wraper" id="step2">
                            <div className="section-heading text-center">
                              <section className="login_wraper">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                      <div className="contact-form2">
                                        <h4 className="text-uppercase text-center">
                                          Add Device (Step 2)
                                        </h4>
                                        <form
                                          onSubmit={handleSubmit3(
                                            onSubmitSteptwo
                                          )}
                                        >
                                          <div className="form-group">
                                            <select
                                              {...register3("parent_id")}
                                              className={`form-control ${errors3.parent_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            >
                                              <option value="">
                                                ---------------------------
                                                Select Area
                                                ---------------------------
                                              </option>
                                              {addedAreas}
                                            </select>
                                            <span
                                              style={{
                                                color: "red",
                                                float: "left",
                                              }}
                                            >
                                              {errors3.parent_id?.message}
                                            </span>
                                          </div>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              {...register3("device_name")}
                                              placeholder="Please enter device name"
                                              className={`form-control ${errors3.device_name
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                            <span
                                              style={{
                                                color: "red",
                                                float: "left",
                                              }}
                                            >
                                              {errors3.device_name?.message}
                                            </span>
                                          </div>

                                          <div className="form-group">
                                            <input
                                              type="text"
                                              {...register3("device_id")}
                                              placeholder="Please enter the device ID "
                                              className={`form-control ${errors3.device_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                            <span
                                              style={{
                                                color: "red",
                                                float: "left",
                                              }}
                                            >
                                              {errors3.device_id?.message}
                                            </span>
                                          </div>

                                          <button
                                            type="button"
                                            style={{
                                              borderRadius: 25,
                                              margin: 10,
                                            }}
                                            className="btn btn-info"
                                            onClick={() => {
                                              setstepOne(true);
                                              setstepTwo(false);
                                            }}
                                          >
                                            Exit
                                          </button>
                                          {stepTwoisLoading ? (
                                            <button
                                              className="btn btn-primary"
                                              style={{ borderRadius: 25 }}
                                            >
                                              Verifying...
                                              <div
                                                className="spinner-border"
                                                style={{
                                                  width: "1rem",
                                                  height: "1rem",
                                                }}
                                              />
                                            </button>
                                          ) : (
                                            <>
                                              <button
                                                type="submit"
                                                style={{
                                                  borderRadius: 25,
                                                  margin: 10,
                                                }}
                                                className="btn btn-primary"
                                                disabled={isSubmitting3}
                                              >
                                                {addDeviceBtnText}
                                              </button>
                                            </>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null
                  ) : null}
                  {/* {isAddVirtualDevice ? (
                    <div className="welcome_wraper" id="step2">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Add Virtual Device
                                  </h4>
                                  <form
                                    onSubmit={handleSubmit10(
                                      onSubmitAddVirtualDevice
                                    )}
                                  >
                                    <div className="form-group">
                                      <select
                                        {...register10("area_id")}
                                        placeholder="Select Area"
                                        className={`form-control ${
                                          errors10.parent_id ? "is-invalid" : ""
                                        }`}
                                      >
                                        {optionTemplate}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors10.parent_id?.message}
                                      </span>
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        {...register10("device_name")}
                                        placeholder="Please enter device name"
                                        className={`form-control ${
                                          errors10.device_name
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        autoComplete="off"
                                      />
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors10.device_name?.message}
                                      </span>
                                    </div>

                                    <div className="form-group">
                                      <select
                                        {...register10("device_ids")}
                                        className={`form-control ${
                                          errors10.device_ids
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        multiple
                                      >
                                        {addedDevicesId}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors10.device_ids?.message}
                                      </span>
                                    </div>
                                    <button
                                      type="submit"
                                      style={{ borderRadius: 25, margin: 10 }}
                                      className="btn btn-primary"
                                      disabled={isSubmitting3}
                                    >
                                      Apply
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null} */}

                  {showWelcomeDiv ? (
                    <>
                      <div className="welcome_wraper">
                        {/* <DeviceSelector /> */}

                        <div className="section-heading text-center">
                          <h2>Welcome to Agile-Sync</h2>
                          {/* <h2>{Object.keys(process.env)}</h2> */}
                          <p className>
                            Where we make your Energy monitoring Simple and
                            Reliable.{" "}
                          </p>
                          <div className="row">
                            <div className="col-lg-6 col-sm-6 ">
                              <div
                                // className="feature-box data-aos"
                                data-aos="fade-up"
                              >
                                <img
                                  src="assets/images/dash1.png"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-sm-6 ">
                              <div
                                // className="feature-box data-aos"
                                data-aos="fade-up"
                              >
                                <img
                                  src="assets/images/dash2.png"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="seperator" />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/*graph chart*/}
                  {showGraph ? (
                    isAddArea ? (
                      <div className="welcome_wraper">
                        <div className="section-heading text-center">
                          <section className="login_wraper">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                  <div className="contact-form2">
                                    <h4 className="text-uppercase text-center">
                                      Add Area
                                    </h4>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                      <div className="form-group">
                                        <select
                                          {...register("parent_id")}
                                          className={`form-control ${errors.parent_id ? "is-invalid" : ""
                                            }`}
                                        >
                                          <option value="">
                                            --------------------------- Select
                                            Area ---------------------------
                                          </option>
                                          {optionTemplate}
                                        </select>
                                        <span
                                          style={{
                                            color: "red",
                                            float: "left",
                                          }}
                                        >
                                          {errors.parent_id?.message}
                                        </span>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          {...register("area_name")}
                                          placeholder="Enter Area name"
                                          className={`form-control ${errors.area_name ? "is-invalid" : ""
                                            }`}
                                          autoComplete="off"
                                        />
                                        <span
                                          style={{
                                            color: "red",
                                            float: "left",
                                          }}
                                        >
                                          {errors.area_name?.message}
                                        </span>
                                      </div>
                                      {isLoading ? (
                                        <button
                                          className="btn btn-primary"
                                          style={{ borderRadius: 25 }}
                                        >
                                          Submit...
                                          <div
                                            className="spinner-border"
                                            style={{
                                              width: "1rem",
                                              height: "1rem",
                                            }}
                                          />
                                        </button>
                                      ) : (
                                        <>
                                          <button
                                            type="submit"
                                            style={{
                                              borderRadius: 25,
                                              margin: 10,
                                            }}
                                            className="btn btn-primary"
                                            disabled={isSubmitting}
                                          >
                                            Submit
                                          </button>
                                          <button
                                            type="button"
                                            style={{ borderRadius: 25 }}
                                            className="btn btn-danger"
                                            onClick={() => setIsAddArea(false)}
                                          >
                                            Close
                                          </button>
                                        </>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    ) : (
                      <div className="grpah_table box_graph">
                        <div className="col-lg-12">
                          <div className="widget_categories right-widget top_heding ">
                            {/* <h4><b>{areaName}</b> - {devicename} */}
                            <h4>
                              <span
                                style={{
                                  background: `${isDeviceStatus}`,
                                  borderRadius: "0px",
                                  fontSize: "55px",
                                }}
                              />
                              {" " + devicename}
                              {/* {devicename + " " + sharedProfile} */}
                            </h4>
                          </div>
                          {isDeviceID.includes("PUC") ||
                            isDeviceID.includes("puc") ||
                            isDeviceID.includes("IEL-MOR") ||
                            isDeviceID.includes("IEL-MOR2") ||
                            isDeviceID.includes("IEL-DC") ? (
                            // { isDeviceID.includes("puc")?(<h1>Hello World</h1>):(<PucDashboard DeviceID={isDeviceID} />)}
                            <>
                              {/* <PucDashboard DeviceID={isDeviceID} /> */}
                              {isDeviceID.includes("IEL-DC") ? (
                                <IelDc
                                  StatementName={statementName}
                                  device_id={isDeviceID}
                                  userID={userID}
                                  isSharedDevice={isSharedDevice}
                                  isDeviceStatus={isDeviceStatus}
                                />
                              ) : (
                                <>
                                  {isDeviceID.includes("IEL-MOR2") ? (
                                    <New_Morocco
                                      SharedProfile={sharedProfile}
                                      userID={userID}
                                      StatementName={statementName}
                                      device_id={isDeviceID}
                                      devicename={devicename}
                                    />
                                  ) : (
                                    <>
                                      {
                                        isDeviceID.includes("IEL-MOR") ? (
                                          <Morocco
                                            SharedProfile={sharedProfile}
                                            userID={userID}
                                            StatementName={statementName}
                                            device_id={isDeviceID}
                                            devicename={devicename}
                                          />
                                        ) : (
                                          <PucDashboard DeviceID={isDeviceID} />
                                        )
                                      }
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <DashBox
                                // isStaticTxtValue3={isStaticTxtValue1}
                                // isStaticTxtValue2={isStaticTxtValue4}
                                // isStaticTxtValue1={isStaticTxtValue3}
                                // isStaticTxtValue4={isStaticTxtValue4}
                                // isStaticValue1={isStaticValue3}
                                // isStaticValue2={isStaticValue4}
                                // isStaticValue3={isStaticValue1}
                                // isStaticValue4={isStaticValue4}
                                isDeviceID={isDeviceID}
                                isGraphStatsLoading={isGraphStatsLoading}
                                isStaticTxtValue1={"Total Power"}
                                isStaticTxtValue2={"Total Energy"}
                                isStaticTxtValue3={"Frequency"}
                                isStaticTxtValue4={"Power Factor"}
                                isStaticTxtValue5={"Temperature"}
                                isStaticValue1={isStaticValue3}
                                isStaticValue2={isStaticValue4}
                                isStaticValue3={isStaticValue1}
                                isStaticValue4={isStaticValue4}
                                isStaticValue5={parseFloat(
                                  isStaticTemperature
                                ).toFixed(2)}
                              />

                              {/* <DeviceStats
                            isStaticTxtValue1={isStaticTxtValue1}
                            isStaticTxtValue2={isStaticTxtValue2}
                            isStaticTxtValue3={isStaticTxtValue3}
                            isStaticTxtValue4={isStaticTxtValue4}
                            isStaticValue1={isStaticValue1}
                            isStaticValue2={isStaticValue2}
                            isStaticValue3={isStaticValue3}
                            isStaticValue4={isStaticValue4}
                            isGraphStatsLoading={isGraphStatsLoading}
                          /> */}

                              {isControl ? (
                                <Control
                                  device_id={isDeviceID}
                                  userID={userID}
                                  isSharedDevice={isSharedDevice}
                                  isDeviceStatus={isDeviceStatus}
                                />
                              ) : (
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                  <div className="row">
                                    {/* {isTemperature == false ? (
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="tags left_wraper">
                                        <Stack
                                          spacing={2}
                                          sx={{ paddingRight: "10px" }}
                                        >
                                          <Button
                                            variant="contained"
                                            className={`${
                                              isPower ? "active" : null
                                            }`}
                                            style={{
                                              borderRadius: 25,
                                              backgroundColor:
                                                "rgb(51, 164, 165)",
                                              textTransform: "capitalize",
                                            }}
                                            size="large"
                                            onClick={() => {
                                              setisPower(true);
                                              setisPowerTotal(true);
                                              setisPowerPhase1(false);
                                              setisPowerPhase2(false);
                                              setisPowerPhase3(false);
                                              setisEnergy(false);
                                              setisTemperature(false);
                                              setisControl(false);
                                              setisEnergyDaily(false);
                                              setisEnergyMonthly(false);
                                              setisActiveRangeSwitch(null);
                                              setisStaticTxtValue1("Frequency");
                                              setisStaticTxtValue2(
                                                "Power Factor"
                                              );
                                              setisStaticTxtValue3("T-Power");
                                              setisStaticTxtValue4(
                                                "T-Energy (Monthly)"
                                              );
                                              setisGraphLabelTxt("Total Power");

                                              UserService.GetLinkedDeviceData(
                                                isDeviceID,
                                                "T_power_A"
                                              )
                                                .then((res) => {
                                                  setIsGraphDataFromSocket(
                                                    false
                                                  );
                                                  setIsFilterGraphData(true);
                                                  setgraphDataFromFilter(
                                                    res.data.data.deviceData
                                                  );
                                                  setIsstartDate(
                                                    res.data.data.deviceData[0]
                                                      .date
                                                  );
                                                  setIsstartDateChange(
                                                    new Date()
                                                      .getTime()
                                                      .toString() +
                                                      Math.floor(
                                                        Math.random() * 1000000
                                                      )
                                                  );
                                                  setTimeout(() => {
                                                    setIsFilterGraphData(false);
                                                  }, 1000);
                                                })
                                                .catch((err) => {
                                                  setIsFilterGraphData(false);
                                                  console.log(err);
                                                });
                                              //get latest stats for total voltage, current, power and energy
                                              UserService.GetLatestDeviceStatsData(
                                                isDeviceID
                                              )
                                                .then((res) => {
                                                  const {
                                                    T_voltage,
                                                    T_current,
                                                    T_power,
                                                    T_energy,
                                                    temperature,
                                                  } =
                                                    res.data.data.deviceData[0];
                                                  setisStaticValue1(T_voltage);
                                                  setisStaticValue2(T_current);
                                                  setisStaticValue3(T_power);
                                                  setisStaticValue4(T_energy);
                                                  setisStaticTemperature(
                                                    temperature
                                                  ); // temperature
                                                })
                                                .catch((err) => {
                                                  console.log(err);
                                                });
                                            }}
                                          >
                                            Power
                                          </Button>
                                          <Button
                                            variant="contained"
                                            className={`${
                                              isEnergy ? "active" : null
                                            }`}
                                            style={{
                                              borderRadius: 25,
                                              backgroundColor:
                                                "rgb(51, 164, 165)",
                                              textTransform: "capitalize",
                                            }}
                                            size="large"
                                            aria-controls={
                                              open ? "basic-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              open ? "true" : undefined
                                            }
                                            onClick={handleClick}
                                            endIcon={<KeyboardArrowDown />}
                                          >
                                            Energy
                                          </Button>
                                          <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleCloseMenu}
                                            MenuListProps={{
                                              "aria-labelledby": "basic-button",
                                            }}
                                          >
                                            <MenuItem
                                              onClick={() =>
                                                handleCloseMenu("daily")
                                              }
                                            >
                                              Daily
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleCloseMenu("monthly")
                                              }
                                            >
                                              Monthly
                                            </MenuItem>
                                          </Menu>
                                        </Stack>
                                      </div>
                                    </div>
                                  </div>
                                ) : null} */}

                                    {/* <div
                                  className={`col-xl-${
                                    isTemperature ? 12 : 10
                                  } col-lg-${isTemperature ? 12 : 8} col-md-${
                                    isTemperature ? 12 : 8
                                  } col-sm-12`}
                                > */}
                                    <div
                                      className={`col-xl-12   col-lg-12 col-md-12 col-sm-12`}
                                    >
                                      <div className="row right_wraper">
                                        <div className="graph_wraper">
                                          {isPower && isPowerTotal ? (
                                            <>
                                              {/* isPowerTotal
                                                isPowerPhase1
                                                isPowerPhase2
                                                isPowerPhase3 */}

                                              <ApexPowerChart
                                                StatementName={statementName}
                                                device_id={isDeviceID}
                                                isFilterGraphData={
                                                  isFilterGraphData
                                                }
                                                graphDataFromFilter={
                                                  graphDataFromFilter
                                                }
                                                phase="T_power_A"
                                                isGraphDataFromSocket={
                                                  isGraphDataFromSocket
                                                }
                                                graphDataFromSocket={
                                                  powerDataFromDB
                                                }
                                                userID={userID}
                                                isSharedDevice={isSharedDevice}
                                                isDeviceStatus={isDeviceStatus}
                                              />
                                            </>
                                          ) : null}
                                          {isPower && isPowerPhase1 ? (
                                            <>
                                              {/* isPowerTotal
                                                isPowerPhase1
                                                isPowerPhase2
                                                isPowerPhase3 */}

                                              <ApexPowerChart
                                                device_id={isDeviceID}
                                                isFilterGraphData={
                                                  isFilterGraphData
                                                }
                                                graphDataFromFilter={
                                                  graphDataFromFilter
                                                }
                                                phase="L1_Power_A"
                                                isGraphDataFromSocket={
                                                  isGraphDataFromSocket
                                                }
                                                graphDataFromSocket={
                                                  powerDataFromDB
                                                }
                                                userID={userID}
                                                isSharedDevice={isSharedDevice}
                                                isDeviceStatus={isDeviceStatus}
                                              />
                                            </>
                                          ) : null}

                                          {isPower && isPowerPhase2 ? (
                                            <>
                                              {/* isPowerTotal
                                                isPowerPhase1
                                                isPowerPhase2
                                                isPowerPhase3 */}

                                              <ApexPowerChart
                                                device_id={isDeviceID}
                                                isFilterGraphData={
                                                  isFilterGraphData
                                                }
                                                graphDataFromFilter={
                                                  graphDataFromFilter
                                                }
                                                phase="L2_Power_A"
                                                isGraphDataFromSocket={
                                                  isGraphDataFromSocket
                                                }
                                                graphDataFromSocket={
                                                  powerDataFromDB
                                                }
                                                userID={userID}
                                                isSharedDevice={isSharedDevice}
                                                isDeviceStatus={isDeviceStatus}
                                              />
                                            </>
                                          ) : null}
                                          {isPower && isPowerPhase3 ? (
                                            <>
                                              {/* isPowerTotal
                                                isPowerPhase1
                                                isPowerPhase2
                                                isPowerPhase3 */}

                                              <ApexPowerChart
                                                device_id={isDeviceID}
                                                isFilterGraphData={
                                                  isFilterGraphData
                                                }
                                                graphDataFromFilter={
                                                  graphDataFromFilter
                                                }
                                                phase="L3_Power_A"
                                                isGraphDataFromSocket={
                                                  isGraphDataFromSocket
                                                }
                                                graphDataFromSocket={
                                                  powerDataFromDB
                                                }
                                                userID={userID}
                                                isSharedDevice={isSharedDevice}
                                                isDeviceStatus={isDeviceStatus}
                                              />
                                            </>
                                          ) : null}
                                          {isTemperature ? (
                                            // <TempetureChart
                                            //   device_id={isDeviceID}
                                            //   isFilterTemData={isFilterTemData}
                                            //   tempetureDataFromFilter={tempetureDataFromFilter}

                                            //   istempetureDataFromSocket={istempetureDataFromSocket}
                                            //   tempetureDataFromSocket={tempetureDataFromSocket}
                                            // />
                                            <>
                                              {/* <ApexTempetureChart
                                                  device_id={isDeviceID}
                                                  isFilterTemData={isFilterTemData}
                                                  tempetureDataFromFilter={tempetureDataFromFilter}
                                                  phase="L3_Power_A"
                                                  istempetureDataFromSocket={istempetureDataFromSocket}
                                                  tempetureDataFromSocket={tempetureDataFromSocket}
                                                /> */}

                                              <ApexPowerChart
                                                device_id={isDeviceID}
                                                isFilterGraphData={
                                                  isFilterGraphData
                                                }
                                                graphDataFromFilter={
                                                  graphDataFromFilter
                                                }
                                                phase="temperature"
                                                isGraphDataFromSocket={
                                                  istempetureDataFromSocket
                                                }
                                                graphDataFromSocket={
                                                  tempetureDataFromSocket
                                                }
                                                userID={userID}
                                                isSharedDevice={isSharedDevice}
                                                isDeviceStatus={isDeviceStatus}
                                              />
                                            </>
                                          ) : null}
                                          {isEnergyDaily ? (
                                            <EnergyChart
                                              energyDataFromDB={
                                                energyDataFromDB
                                              }
                                              chartType="daily"
                                            />
                                          ) : null}

                                          {isEnergyMonthly ? (
                                            <EnergyChart
                                              energyDataFromDB={
                                                monthlyenergyDataFromDB
                                              }
                                              chartType="monthly"
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <DashBox2
                                isStaticTxtValue1={isStaticTxtValue1}
                                isStaticTxtValue2={"isStaticTxtValue2"}
                                isStaticTxtValue3={isStaticTxtValue3}
                                isStaticTxtValue4={"isStaticTxtValue4"}
                                isStaticValue1={isStaticValue1}
                                isStaticValue2={isStaticValue2}
                                isStaticValue3={55}
                                isStaticValue4={55}
                                isGraphStatsLoading={isGraphStatsLoading}
                                isDeviceID={isDeviceID}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )
                  ) : isAddArea ? (
                    <div className="welcome_wraper">
                      <div className="section-heading text-center">
                        <section className="login_wraper">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="contact-form2">
                                  <h4 className="text-uppercase text-center">
                                    Add Area
                                  </h4>
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                      <select
                                        {...register("parent_id")}
                                        className={`form-control ${errors.parent_id ? "is-invalid" : ""
                                          }`}
                                      >
                                        <option value="">
                                          --------------------------- Select
                                          Area ---------------------------
                                        </option>
                                        {optionTemplate}
                                      </select>
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors.parent_id?.message}
                                      </span>
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        {...register("area_name")}
                                        placeholder="Enter Area name"
                                        className={`form-control ${errors.area_name ? "is-invalid" : ""
                                          }`}
                                        autoComplete="off"
                                      />
                                      <span
                                        style={{ color: "red", float: "left" }}
                                      >
                                        {errors.area_name?.message}
                                      </span>
                                    </div>
                                    {isLoading ? (
                                      <button
                                        className="btn btn-primary"
                                        style={{ borderRadius: 25 }}
                                      >
                                        Submit...
                                        <div
                                          className="spinner-border"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          style={{
                                            borderRadius: 25,
                                            margin: 10,
                                          }}
                                          className="btn btn-primary"
                                          disabled={isSubmitting}
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          style={{ borderRadius: 25 }}
                                          className="btn btn-danger"
                                          onClick={() => setIsAddArea(false)}
                                        >
                                          Close
                                        </button>
                                      </>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  ) : null}
                  {/* {isVirtualDevice &&  displayVirtualDeviceType== "virtual_device"? ( */}
                  {isVirtualDevice ? (
                    <>
                      {displayVirtualDeviceType == "virtual_device" ? (
                        <VirtualDevice
                          userID={userID}
                          id={deviceID}
                          DeviceName={devicename}
                          DeviceObject={DeviceObject}
                        />
                      ) : (
                        <VirtualDeviceMorroco
                          userID={userID}
                          id={deviceID}
                          DeviceName={devicename}
                          DeviceObject={DeviceObject}
                        />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer STYLES  */}
      <Footer />
      {/* FOOTER STYLES END */}
    </div>
  );
};
export default Dashboard;
